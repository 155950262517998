import React, { useState, useMemo, useEffect } from "react";
import Styles from "./AddParticipiantModal.module.css";
import SearchBox from "../SearchBox";
import useGroupParticipants from "../../hooks/videoCall/useGroupParticipant";
import { useSelector } from "react-redux";
// import SkeletonLoader from "./SkeletonLoader"; // Import the skeleton loader component
import AddparticipantSkeleton from "../Skeletons/AddParticipantsSkeleton";

const AddParticipiantModal = ({
  onClose,
  participants,
  socketRef,
  user,
  roomInfo,
  participantInfo,
  handleHangUpRingingCall,
  setAddParticipantInfo,
  roomDetails
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const roomId = roomInfo.get('roomId');
  const groupId = roomInfo.get('groupId')

  const {
    participants: groupParticipants,
    isLoading,
    error
  } = useGroupParticipants({ groupId });

  const { onlineUsers } = useSelector((state) => state.onlineUsers);

  // Create a map of user IDs to online status for efficient lookup
  const onlineStatusMap = useMemo(() => {
    const map = new Map();
    onlineUsers.forEach(user => {
      map.set(user.userId, true);
    });
    return map;
  }, [onlineUsers]);

  // Effect to update ringing participants when online status changes
  useEffect(() => {
    // Check if any ringing participants have gone offline
    if (participantInfo?.ringingParticipantsIds?.length > 0) {
      const updatedRingingIds = participantInfo.ringingParticipantsIds.filter(
        participantId => onlineStatusMap.has(participantId)
      );

      // If we found any participants that went offline, update the state
      if (updatedRingingIds.length !== participantInfo.ringingParticipantsIds.length) {
        console.log('Participants went offline while ringing, updating state');
        setAddParticipantInfo(prev => ({
          ...prev,
          ringingParticipantsIds: updatedRingingIds
        }));
      }
    }
  }, [onlineStatusMap, participantInfo?.ringingParticipantsIds, setAddParticipantInfo]);

  const participantsArray = useMemo(() => {
    if (!participants) return [];
    return Array.from(participants.entries()).map(([id, data]) => ({
      socketId: id,
      ...data
    }));
  }, [participants]);

  const { participantsOnCall, participantsNotOnCall } = useMemo(() => {
    if (!groupParticipants || !participantsArray.length) {
      return { participantsOnCall: [], participantsNotOnCall: [] };
    }

    const onCallUserIds = new Set(participantsArray.map(p => p.userId));

    const onCall = [];
    const notOnCall = [];

    groupParticipants.forEach(participant => {
      if (participant) {
        const participantData = {
          id: participant._id,
          name: participant.name,
          email: participant.email,
          role: participant.role,
          status: participant.status
        };

        if (onCallUserIds.has(participant._id)) {
          onCall.push({
            ...participantData,
            onCall: true,
            isMuted: false
          });
        } else {
          notOnCall.push({
            ...participantData,
            onCall: false
          });
        }
      }
    });

    return { participantsOnCall: onCall, participantsNotOnCall: notOnCall };
  }, [groupParticipants, participantsArray]);

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    setSearchTerm(searchValue);
  };

  const filteredParticipantsOnCall = useMemo(() => {
    return participantsOnCall.filter(p =>
      p.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      p.email.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [participantsOnCall, searchTerm]);

  const filteredParticipantsNotOnCall = useMemo(() => {
    return participantsNotOnCall.filter(p =>
      p.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      p.email.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [participantsNotOnCall, searchTerm]);

  const toggleMute = (id) => {
    console.log(`Toggle mute for participant ${id}`);
  };

  const removeParticipant = (id) => {
    console.log(`Remove participant ${id} from call`);
  };

  const inviteUserToCall = (targetSocketId, participantId, participantName, isCallAlreadyRinging) => {
    if (isCallAlreadyRinging) return;
    const isCallPreviouslyRejected = participantInfo.declinedParticipantIds.includes(participantId);

    if (isCallPreviouslyRejected) {
      setAddParticipantInfo((prev) => ({
        ...prev,
        declinedParticipantIds: prev.declinedParticipantIds.filter((id) => id !== participantId)
      }))
    }

    socketRef.current.emit('inviteToCall', {
      targetSocketId: targetSocketId,
      participantId: participantId,
      participantName: participantName,
      groupId: groupId,
      senderInfo: {
        name: user?.name,
        groupId: groupId,
        roomId: roomId,
        groupName: 'group-video-call-test',
        message: `${user?.name} is calling`
      }
    });
  };

  const handleEndRingingCall = async (participantSocketId, participantId) => {
    // TODO: REPLACE WITH CALLBACK INSTEAD USE SIMPLE EVENT LISTNER, TO AVOID TIMEOUT ERRORS
    socketRef.current.emit('hangUpRingingCall', { participantSocketId }, ({ status }) => {
      if (status) {
        handleHangUpRingingCall(participantId)
      }
    })
  }

  return (
    <>
      <div className={Styles.modalOverlay} onClick={onClose}></div>
      <div className={Styles.addParticipantModal}>
        <div className={Styles.groupCallLogo}>
          <svg className={Styles.groupCallIcon}>
            <use xlinkHref={`/assets/sprite.svg#icon-groupCall`} />
          </svg>
          <button
            onClick={onClose}
            style={{
              border: "none",
              cursor: "pointer",
              position: "relative",
              bottom: "1rem",
              left: "16rem",
              height: "2rem",
              backgroundColor: "#fff",
            }}
          >
            <svg className={Styles.iconClose}>
              <use xlinkHref={`/assets/sprite.svg#icon-close-participants`} />
            </svg>
          </button>
        </div>

        <span className={Styles.groupName}>{roomDetails?.groupName || "Group Name"}</span>

        <SearchBox
          style={{
            width: "39rem",
            marginTop: "2rem",
            marginBottom: "2rem",
            border: "1px solid #aaa",
            padding: "3px 1.5rem",
            marginLeft: "2rem",
          }}
          placeholder={"Search"}
          value={searchTerm}
          onChange={handleSearch}
        />

        {isLoading ? (
          <div style={{ overflowY: 'auto', height: '60%' }}>
            <div className={Styles.participants}>
              <span className={Styles.onCallText}>ON THIS CALL</span>
              <AddparticipantSkeleton count={3} />
            </div>
            <div className={Styles.participants}>
              <span className={Styles.onCallText}>NOT ON THIS CALL</span>
              <AddparticipantSkeleton count={5} />
            </div>
          </div>
        ) : error ? (
          <div className={Styles.errorState}>Error loading participants: {error}</div>
        ) : (
          <div style={{ overflowY: 'auto', height: '60%' }}>
            {filteredParticipantsOnCall.length > 0 && <div className={Styles.participants}>
              <span className={Styles.onCallText}>ON THIS CALL</span>
              {filteredParticipantsOnCall.length === 0 ? (
                <div className={Styles.emptyState}></div>
              ) : (
                filteredParticipantsOnCall.map((participant) => {
                  const isOnline = onlineUsers.some(user => user.userId === participant.id);

                  return (
                    <div key={participant.id} className={Styles.participant1}>
                      <div className={Styles.profile}>
                        <svg className={Styles.profileIcon}>
                          <use xlinkHref={`/assets/sprite.svg#icon-profile`} />
                        </svg>
                      </div>
                      <div className={Styles.participantCard}>
                        <div className={Styles.info}>
                          <span className={Styles.name}>{participant.name}</span>
                          <span className={Styles.email}>{participant.email}</span>
                          <span style={{
                            color: isOnline ? "#4caf50" : "#999",
                            fontSize: "1.3rem",
                            marginLeft: "5px"
                          }}>
                            {isOnline ? "● Online" : "● Offline"}
                          </span>
                          {participant.role === "admin" && (
                            <span style={{
                              marginLeft: "5px",
                              fontWeight: "bold"
                            }}>
                              (Admin)
                            </span>
                          )}
                        </div>
                        {/* <div className={Styles.icons}>
                          <svg
                            className={Styles.icon}
                            onClick={() => toggleMute(participant.id)}
                            style={{ cursor: "pointer", width: "2rem" }}
                            title={participant.isMuted ? "Unmute" : "Mute"}
                          >
                            <use
                              xlinkHref={
                                participant.isMuted
                                  ? `/assets/sprite.svg#icon-unmute-participants`
                                  : `/assets/sprite.svg#icon-mute-participants`
                              }
                            />
                          </svg>
                          <svg
                            className={Styles.icon}
                            onClick={() => removeParticipant(participant.id)}
                            style={{ cursor: "pointer" }}
                            title="Remove from call"
                          >
                            <use xlinkHref={`/assets/sprite.svg#icon-remove-participants`} />
                          </svg>
                        </div> */}
                      </div>
                    </div>
                  );
                })
              )}
            </div>}

            {filteredParticipantsNotOnCall.length > 0 && <div className={Styles.participants} >
              <span className={Styles.onCallText}>NOT ON THIS CALL</span>
              {filteredParticipantsNotOnCall.length === 0 ? (
                <div className={Styles.emptyState}></div>
              ) : (
                filteredParticipantsNotOnCall.map((participant) => {
                  const isOnline = onlineUsers.some(user => user.userId === participant.id);

                  return (
                    <div key={participant.id} className={Styles.participant1}>
                      <div className={Styles.profile}>
                        <svg className={Styles.profileIcon}>
                          <use xlinkHref={`/assets/sprite.svg#icon-profile`} />
                        </svg>
                      </div>
                      <div className={Styles.participantCard}>
                        <div className={Styles.info}>
                          <span className={Styles.name}>{participant.name}</span>
                          <span className={Styles.email}>{participant.email}</span>
                          {participant.status === "pending" && (
                            <span className={Styles.pendingStatus}>
                              <strong> (Invitation Pending)</strong>
                            </span>
                          )}
                          {participant.role === "admin" && (
                            <span style={{
                              marginLeft: "5px",
                              fontWeight: "bold"
                            }}>
                              (Admin)
                            </span>
                          )}
                          <span style={{
                            color: isOnline ? "#4caf50" : "#999",
                            fontSize: "1.3rem",
                            marginLeft: "5px"
                          }}>
                            {isOnline ? "● Online" : "● Offline"}
                          </span>
                        </div>
                        <div className={Styles.icons}>
                          {(() => {
                            const isCallRinging = participantInfo?.ringingParticipantsIds?.includes(participant.id);
                            const isCallRejected = participantInfo.declinedParticipantIds?.includes(participant.id);

                            if (isOnline) {
                              return (
                                <>
                                  {!isCallRinging && <svg
                                    className={Styles.icon}
                                    style={{
                                      fill: isCallRinging ? "grey" : "var(--primary-color)"
                                    }}
                                    title="Call participant"
                                  >
                                    <use style={{ cursor: 'pointer' }}
                                      onClick={() => inviteUserToCall(
                                        onlineUsers.find(user => user.userId === participant.id)?.socketId,
                                        participant.id,
                                        participant.name,
                                        isCallRinging
                                      )}
                                      xlinkHref={`/assets/sprite.svg#icon-ring-participants`} />
                                  </svg>
                                  }

                                  {isCallRinging &&
                                    <span className={Styles.ringingText}>
                                      Ringing
                                    </span>
                                  }

                                  {isCallRinging &&
                                    <span
                                      className={Styles.endCalltext}
                                      onClick={() => handleEndRingingCall(
                                        onlineUsers.find(user => user.userId === participant.id)?.socketId,
                                        participant.id
                                      )}
                                    >
                                      Hang Up
                                    </span>
                                  }

                                  {isCallRejected && <span className={Styles.callDeclinedText}>
                                    Call Declined
                                  </span>
                                  }
                                </>
                              );
                            }
                            return null;
                          })()}
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
            </div>}
          </div>
        )}
      </div>
    </>
  );
};

export default AddParticipiantModal;