import React, { useEffect, useRef, useState } from 'react';
import Modal from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import ModalButtons from '../../components/Modal/ModalButtons';
import Teams from '../../assets/teams.mp3';
import { useDispatch, useSelector } from 'react-redux';
import { CALL_ANSWER, CALL_DECLINE } from '../../redux/constants/userConstants';
import { useHistory } from 'react-router-dom';

const GroupCallModal = ({ callModal, onDecline, socket, roomDetails, setGroupCallModal, handleDeclineSocketCall }) => {
    const [callerData, setCallerData] = useState(null);
    const audioRef = useRef(null);
    const dispatch = useDispatch();
    const history = useHistory();

    console.log({ roomDetails })

    // const { userInfo: { data: { user } } } = useSelector((user) => user.userLogin);

    // Ensure the audio plays when the modal opens and stops when it closes
    useEffect(() => {
        if (!audioRef.current) return; // Prevent null error

        if (callModal) {
            const playAudio = async () => {
                try {
                    await audioRef.current.play();
                } catch (error) {
                    console.error('Audio playback error:', error);
                }
            };
            playAudio();
        } else {
            audioRef.current.pause();
            audioRef.current.currentTime = 0; // Reset audio
        }

        return () => {
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current.currentTime = 0;
            }
        };
    }, [callModal]);

    // Handle incoming call event
    useEffect(() => {
        if (socket) {
            socket.on('groupCallAlert', ({ groupId, roomId, callerId, callerName, signal }) => {
                setCallerData({ groupId, roomId, callerId, callerName, signal });

                if (callModal && audioRef.current) {
                    audioRef.current.play();
                }
            });
        }

        return () => {
            if (socket) socket.off('groupCallAlert');
        };
    }, [socket, callModal]);

    // Handle answer call
    const handleAnswer = () => {
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
        }
        const { groupId, roomId } = roomDetails;
        window.location.href = `/#/group-video-call-test?roomId=${roomId}&groupId=${groupId}`;
        setGroupCallModal(false);
    };

    const handleDecline = () => {
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
        }
        handleDeclineSocketCall(roomDetails.senderSocketId, 'Call Declined');
        setGroupCallModal(false);
    };

    return (
        <>
            {/* Audio element is placed outside the JSX return to ensure it exists */}
            <audio src={Teams} loop ref={audioRef} />

            <Modal show={callModal}>
                <div style={{ display: "flex", gap: "0.3rem", flexDirection: "column", alignItems: "start", paddingBottom: "1.3rem"  }}>
                    <ModalHeading
                        style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem', alignItems: "start" }}
                        heading={
                            `Incoming video call from ${roomDetails?.groupName
                            || 'Group Video Call'}`} subHeading={`By ${roomDetails?.callerName}`} />

                    <div style={{ display: "inline-block", padding: "0.6rem 2rem" }}>
                        <ModalButtons
                            submitButtonLabel='Answer'
                            onSubmit={handleAnswer}
                            cancelButtonLabel='Decline'
                            onCancel={handleDecline}
                        />
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default GroupCallModal;
