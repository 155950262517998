import React, { useState, useRef, useEffect } from 'react';
import styles from '../GroupVideoCall.module.css';

const GridLayout = ({ items }) => {
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
    const [allItemsCache, setAllItemsCache] = useState([]);
    const [itemsPerPage, setItemsPerPage] = useState(3);
    const gridRef = useRef(null);

    // Debug logging function
    const logDebug = (message, data) => {
        console.log(`[GridLayout Debug] ${message}`, data || '');
    };

    // Adjust items per page based on screen size and number of participants
    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;

            // Dynamically adjust participants per page based on screen size
            if (width < 768) {
                setItemsPerPage(2); // Mobile: 2 participants per page
            } else if (width < 1200) {
                setItemsPerPage(4); // Tablet/small desktop: 4 participants per page
            } else {
                setItemsPerPage(4); // Large desktop: 6 participants per page
            }
        };

        // Set initial value
        handleResize();

        // Add resize listener
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // Update pages when items or itemsPerPage changes
    useEffect(() => {
        setAllItemsCache(items);

        const pages = Math.max(1, Math.ceil(items.length / itemsPerPage));
        setTotalPages(pages);

        // Reset to first page if current page is beyond total pages
        if (currentPage >= pages && pages > 0) {
            setCurrentPage(0);
        }

        logDebug(`Updated with ${items.length} items, ${itemsPerPage} per page, total pages: ${pages}`);
    }, [items, itemsPerPage]);

    const handlePrevPage = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
            logDebug("Navigating to previous page:", currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages - 1) {
            setCurrentPage(currentPage + 1);
            logDebug("Navigating to next page:", currentPage + 1);
        }
    };

    const startIndex = currentPage * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, allItemsCache.length);
    const currentItems = allItemsCache.slice(startIndex, endIndex);
    const showNavigation = allItemsCache.length > itemsPerPage;

    // Log the current layout information when it changes
    useEffect(() => {
        if (currentItems.length === 3) {
            logDebug("Rendering 3-participant layout", {
                totalItems: allItemsCache.length,
                currentItems: currentItems.length,
                currentPage
            });
        }
    }, [currentItems, currentPage, allItemsCache.length]);

    return (
        <div className={styles.gridLayoutWrapper}>
            <div
                className={styles.gridContainer}
                data-count={currentItems.length}
                ref={gridRef}
            >
                {currentItems.map((item, index) => {
                    // For 3 participants, always make the third one (index 2) the featured one
                    const isFeatured = currentItems.length === 3 && index === 2;

                    if (isFeatured) {
                        logDebug("Setting featured item", { index, globalIndex: startIndex + index });
                    }

                    return (
                        <div
                            key={`grid-item-${startIndex + index}`}
                            className={styles.gridItem}
                            data-index={startIndex + index}
                            data-position={isFeatured ? 'featured' : ''}
                        >
                            {item}
                        </div>
                    );
                })}
            </div>

            {showNavigation && (
                <>
                    <button
                        className={`${styles.paginationButtonLeft} ${currentPage === 0 ? styles.disabled : ''}`}
                        onClick={handlePrevPage}
                        disabled={currentPage === 0}
                        aria-label="Previous page"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <polyline points="15 18 9 12 15 6"></polyline>
                        </svg>
                    </button>
                    <div className={styles.pageIndicator}>
                        {currentPage + 1} / {totalPages}
                    </div>
                    <button
                        className={`${styles.paginationButtonRight} 
                        
                         ${currentPage === totalPages - 1 ? styles.disabled : ''}`}
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages - 1}
                        aria-label="Next page"
                        style={{
                            top: `27%`
                        }}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <polyline points="9 18 15 12 9 6"></polyline>
                        </svg>
                    </button>
                </>
            )}
        </div>
    );
};

export default GridLayout;