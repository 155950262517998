import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import useApi from '../useApi';

/**
 * Hook for fetching group details by ID
 * @param {Object} options - Hook options
 * @param {string} options.groupId - ID of the group to fetch
 * @param {boolean} options.autoFetch - Whether to fetch automatically on mount
 * @returns {Object} Group details data and helper functions
 */
const useGroupDetails = ({ groupId, autoFetch = true, socket, sendCallEndedMessage }) => {
    const [groupDetails, setGroupDetails] = useState(null);
    const [videoCallInfo, setVideoCallInfo] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const { makeApiCall, loading } = useApi({
        url: `/group/${groupId}`,
        method: 'GET'
    });

    const fetchGroupDetails = async () => {
        if (!groupId) {
            setError('Group ID is required');
            return { success: false, error: 'Group ID is required' };
        }

        setIsLoading(true);
        setError(null);

        try {
            const { response, error } = await makeApiCall();

            if (response?.success === true) {
                setGroupDetails(response.group);
                // Set video call info when receiving group details
                if (response.group?.videoCall) {
                    setVideoCallInfo({
                        groupId: response.group._id,
                        roomId: response.group.videoCall.roomId,
                        isActive: response.group.videoCall.isActive === true,
                        startedBy: response.group.videoCall.startedBy
                    });
                } else {
                    // Reset video call info if there's no active call
                    setVideoCallInfo({
                        isActive: false
                    });
                }
                return { success: true, data: response.group };
            }

            if (response?.success === false) {
                const errorMessage = response.message || 'Failed to fetch group details';
                setError(errorMessage);
                toast.error(errorMessage);
                return { success: false, error: errorMessage };
            }

            if (error) {
                setError(error.message || 'An error occurred');
                toast.error(error.message || 'An error occurred');
                return { success: false, error: error.message };
            }
        } catch (err) {
            const errorMessage = err.message || 'An unexpected error occurred';
            setError(errorMessage);
            toast.error(errorMessage);
            return { success: false, error: errorMessage };
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (groupId && autoFetch) {
            fetchGroupDetails();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupId]);

    useEffect(() => {
        if (socket) {
            // Listen for group call initiated events
            socket.on('groupCallInitiated', (response) => {
                if (response.groupId === groupId) {
                    setVideoCallInfo({
                        ...response,
                        isActive: true
                    });
                }
            });

            // Listen for call ended events
            // Add this to your useEffect for callEnded
            // Add this to your useEffect for callEnded
            socket.on('callEnded', (data) => {
                if (data.groupId === groupId) {
                    setVideoCallInfo({ isActive: false, roomId: null });

                    // Send the call ended message if function provided
                    if (sendCallEndedMessage && typeof sendCallEndedMessage === 'function') {
                        sendCallEndedMessage(data);
                    }
                }
            });
        }

        return () => {
            if (socket) {
                socket.off('groupCallInitiated')
                socket.off('callEnded');
            }
        };
    }, [socket, groupId, videoCallInfo]);

    return {
        groupDetails,
        videoCallInfo,
        setVideoCallInfo,
        setGroupDetails,
        isLoading: isLoading || loading,
        error,
        fetchGroupDetails,
        refetch: fetchGroupDetails,
        videoCallStatus: groupDetails?.videoCallStatus || null,
        isVideoCallActive: videoCallInfo?.isActive === true,
        isUserInCall: groupDetails?.videoCallStatus?.isUserInCall === true
    };
};

export default useGroupDetails;