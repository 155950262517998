import React, { Fragment, useEffect, useState } from "react";
import CryptoJS from "crypto-js";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import toast, { Toaster } from "react-hot-toast";
import Navigation from "./navigation";
import { io } from "socket.io-client";
// import { BASE_SOCKET_URL } from "./redux/actions/ip";
import {
	CALL_DECLINE,
	CALL_RESET,
	ONLINE_USERS,
} from "./redux/constants/userConstants";
import { getAllNotificationsAction } from "./redux/actions/chatActions";
import IncomingCallModal from "./screens/VideoCallScreen/IncomingCallModalNew";
import IncomingGroupCallModal from "./screens/VideoCallScreen/IncomingGroupCallModal";

import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import {
	getClinicUserCount,
	getClientLogoAction,
	getLoggedinUserAction,
} from "./redux/actions/userActions";
import SuccessToast from "./components/Toast/SuccessToast";
import SuccessMessageToast from "./components/Toast/SuccessMessageToast";
// import { useHistory } from 'react-router-dom';

const App = () => {
	const dispatch = useDispatch();
	const [socket, setSocket] = React.useState(null);
	const [callModal, setCallModal] = React.useState(false);
	const [groupCallModal, setGroupCallModal] = React.useState(false);
	const [toastMessage, setToastMessage] = useState("");
	const [showSuccessToast, setShowSuccessToast] = useState(false);
	const [roomDetails, setRoomDetails] = useState({ roomId: '', groupId: '', groupName: '' });
	const userLogin = useSelector((state) => state.userLogin);

	const callReducer = useSelector((state) => state.callReducer);

	const setupSocket = React.useCallback(
		(id) => {
			if (id && !socket) {
				const newSocket = io.connect(
					process.env.REACT_APP_BASE_SOCKET_URL,
					{
						query: {
							id,
							timezone:
								Intl.DateTimeFormat().resolvedOptions()
									.timeZone,
						},
					}
				);

				newSocket.on("disconnect", () => {
					setSocket(null);
					setTimeout(setupSocket, 3000);
					// toast.error('You went offline!', {
					//   style: {
					//     borderRadius: '10px',
					//     background: '#3C0016',
					//     color: '#fff',
					//   },
					// });
				});

				newSocket.on("connect", () => {
					// toast.success('You are now online!', {
					//   style: {
					//     borderRadius: '10px',
					//     background: '#3C0016',
					//     color: '#fff',
					//   },
					// });
				});

				setSocket(newSocket);
			}
		},
		[socket]
	);

	const handleDeclineSocketCall = (senderSocketId, msg) => {
		if (socket) {
			socket.emit('callDeclined', { to: senderSocketId, from: socket.id, rejected: true, msg });


		}
	}

	React.useEffect(() => {
		 console.log('callReducer', callReducer);
		if (callReducer.status && callReducer.status === "Incoming") {
			setCallModal(true);
		} else {
			setCallModal(false);
		}
		if (callReducer.status && callReducer.status === "Decline") {
			dispatch({ type: CALL_RESET });
			toast.error(`Call declined!`, {
				style: {
					borderRadius: "10px",
					background: "red",
					color: "#fff",
				},
			});
		}
	}, [callReducer, dispatch]);

	React.useEffect(() => {
		if (socket) {

			socket.on('groupCallAlert', ({ groupId, roomId, groupName, message, senderSocketId, callerName }) => {
				console.log({ groupId })
				setGroupCallModal(true);
				setRoomDetails({ groupId, roomId, groupName, callerName, senderSocketId });
				setTimeout(() => {
					setGroupCallModal(false);
					setRoomDetails({})
					handleDeclineSocketCall(senderSocketId, 'No Answer')
				}, 40000);
			})

			socket.on('callInvitation', ({ groupName, groupId, roomId, senderSocketId, callerName }) => {
				console.log('call invitation triggered', callerName);
				setGroupCallModal(true);
				setRoomDetails({ roomId, groupId, groupName, callerName, senderSocketId });
				setTimeout(() => {
					setGroupCallModal(false);
					setRoomDetails({})
					handleDeclineSocketCall(senderSocketId, 'No Answer')
				}, 40000);
			});

			socket.on('incomingCallHangedUp', (callback) => {
				setGroupCallModal(false);
				setRoomDetails({});

				if (typeof callback === 'function') {
					callback({ declined: true, timestamp: Date.now() });
				}
			});

			socket.on('callEnded', (data) => {
				setGroupCallModal(false);
				setRoomDetails({})
			});
		}

		return () => {
			if (socket) {
				socket.off('groupCallAlert')
				socket.off('callInvitation')
				socket.off('incomingCallHangedUp')
				socket.off('callEnded');
			}
		};
	}, [socket]);

	React.useEffect(() => {
		if (
			userLogin &&
			userLogin.userInfo &&
			userLogin.userInfo.data &&
			userLogin.userInfo.data.user &&
			userLogin.userInfo.data.user._id &&
			userLogin.userInfo.data.user.modules &&
			userLogin.userInfo.data.user.modules.chat
		) {
			setupSocket(userLogin.userInfo.data.user._id);
		}
	}, [setupSocket, userLogin]);

	// useEffect(() => {
	//   dispatch(getLoggedinUserAction());
	// }, [dispatch])

	React.useEffect(() => {
		const getClinicTotalUsers = async (clientId) => {
			dispatch(getClinicUserCount(clientId));
		};
		if (
			userLogin &&
			userLogin.userInfo &&
			userLogin.userInfo.data &&
			userLogin.userInfo.data.user &&
			userLogin.userInfo.data.user.role
		) {
			if (userLogin.userInfo.data.user.role === "fileAdmin") {
				getClinicTotalUsers(userLogin.userInfo.data.user.clinic);
			} else if (userLogin.userInfo.data.user.role === "clinic") {
				getClinicTotalUsers(userLogin.userInfo.data.user._id);
			}
		}
	}, [userLogin?.userInfo?.data?.user?.role, dispatch]);

	// React.useEffect(() => {
	//   const getClinicTotalUsers = async (clientId) => {
	//     dispatch(getClinicUserCount(clientId));

	//   };
	//   if (
	//     loggedinUser &&
	//     loggedinUser.userInfo &&
	//     loggedinUser.userInfo.data &&
	//     loggedinUser.userInfo.data.data
	//   ) {
	//     if (loggedinUser.userInfo.data.data.role === 'fileAdmin') {
	//       getClinicTotalUsers(loggedinUser.userInfo.data.data.clinic._id);
	//     } else if (loggedinUser.userInfo.data.data.role === 'clinic') {
	//       getClinicTotalUsers(loggedinUser.userInfo.data.data._id);
	//     }
	//   }
	// }, [loggedinUser, dispatch]);

	React.useEffect(() => {
		if (
			!(
				userLogin &&
				userLogin.userInfo &&
				userLogin.userInfo.data &&
				userLogin.userInfo.data.user &&
				userLogin.userInfo.data.user._id
			) &&
			socket
		) {
			socket.disconnect();
		}
	}, [socket, userLogin]);

	React.useEffect(() => {
		if (
			userLogin &&
			userLogin.userInfo &&
			userLogin.userInfo.data &&
			userLogin.userInfo.data.user &&
			userLogin.userInfo.data.user._id
		) {
			dispatch(getAllNotificationsAction("get", "", 1));
			dispatch(getClientLogoAction());
		}
	}, [dispatch, userLogin]);

	React.useEffect(() => {
		if (socket) {
			socket.on("online-users", (user) => {
				dispatch({ type: ONLINE_USERS, payload: user.onlineUsers });
			});
			// socket.on('callDecline', (user) => {
			// 	toast.success(`Call declined!`, {
			// 		style: {
			// 			borderRadius: '10px',
			// 			background: 'red',
			// 			color: '#fff'
			// 		}
			// 	});
			// });

			socket.on("newIncMessage", (message) => {
				if (
					window.location.hash !== "#/chat" &&
					message &&
					message.message &&
					message.message.senderName
				) {
					setToastMessage(
						`${message.message.senderName} has sent you a new message`
					);
					setShowSuccessToast(true);
				}
			});

			socket.on("sendRequest", () => {
				dispatch(getAllNotificationsAction("get", "", 1));
			});

			socket.on("GroupRequestSendResponse", () => {
				dispatch(getAllNotificationsAction("get", "", 1));
			});

			socket.on("AcceptGroupRequestResponse", () => {
				dispatch(getAllNotificationsAction("get", "", 1));
			});

			socket.on("acceptRequest", () => {
				dispatch(getAllNotificationsAction("get", "", 1));
			});
			socket.on("removeRequest", () => {
				dispatch(getAllNotificationsAction("get", "", 1));
			});
			socket.on("cancelRequest", () => {
				dispatch(getAllNotificationsAction("get", "", 1));
			});
		}
	}, [socket, dispatch, userLogin]);

	return (
		<Fragment>
			<Navigation
				userLogin={userLogin}
				socket={socket}
				io={io}
				setSocket={setSocket}
			/>
			<Toaster position="bottom-center" reverseOrder={false} />
			<ToastContainer position="bottom-right" />
			{/* <IncomingCallModal
				callModal={callModal}
				onDecline={() => {
					if (socket) {
						socket.on("callDecline", {
							id:
								callReducer && callReducer.data
									? callReducer.data.socketId
									: "",
						});
					}
				}}
			/> */}
			<IncomingGroupCallModal
				callModal={groupCallModal}
				setGroupCallModal={setGroupCallModal}
				roomDetails={roomDetails}
				handleDeclineSocketCall={handleDeclineSocketCall}
			/>
			<SuccessMessageToast
				message={toastMessage}
				showToast={showSuccessToast}
				onClose={() => setShowSuccessToast(false)}
				duration={6500}
			/>
		</Fragment>
	);
};

export default App;
