import React from 'react'
import styles from './Skeletons.module.css'

const GroupVideoCallSkeleton = () => {
    return (
        
        <div className={styles.videoCall_container}>
            <div className={styles.videoCall_spinner}></div>
            <p className={styles.videoCall_text}>Loading...</p>
        </div>
    )
}

export default GroupVideoCallSkeleton