function generateMeetingId() {
    const characters = 'abcdefghijklmnopqrstuvwxyz';

    let firstBlock = '';
    for (let i = 0; i < 3; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        firstBlock += characters[randomIndex];
    }

    let middleBlock = '';
    for (let i = 0; i < 4; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        middleBlock += characters[randomIndex];
    }

    let lastBlock = '';
    for (let i = 0; i < 3; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        lastBlock += characters[randomIndex];
    }

    return `${firstBlock}-${middleBlock}-${lastBlock}`;
}

export default generateMeetingId;