import React from "react";
import Styles from "./Skeletons.module.css";

const AddparticipantSkeleton = ({ count = 3 }) => {
    return (
        <div className={Styles.addParticipantSkeletonContainer}>
            {Array(count).fill().map((_, index) => (
                <div key={index} className={Styles.addParticipantParticipant1}>
                    <div className={Styles.addParticipantProfileSkeleton}>
                        <div className={Styles.addParticipantAvatarSkeleton}></div>
                    </div>
                    <div className={Styles.addParticipantParticipantCard}>
                        <div className={Styles.addParticipantInfoSkeleton}>
                            <div className={Styles.addParticipantNameSkeleton}></div>
                            <div className={Styles.addParticipantEmailSkeleton}></div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default AddparticipantSkeleton;