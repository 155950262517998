import React, { Fragment, useEffect } from 'react';
import { HashRouter as Router, Route, Redirect } from 'react-router-dom';

import LoginScreen from '../screens/LoginScreen';
import ForgotPassword from '../screens/ForgotPassword';

import ClientsScreen from '../screens/ClientsScreen';
import UsersScreen from '../screens/UsersScreen';

import FileExchangeScreen from '../screens/FileExchangeScreen';
import DownloadFileScreen from '../screens/FileExchangeScreen/DownloadFileScreen';
import MyProfileScreen from '../screens/MyProfileScreen';
import LogsScreen from '../screens/Logs';
import ChatScreen from '../screens/ChatScreenNew';
import ChatRequestsScreen from '../screens/ChatScreenNew/ChatRequests';

import VideoCallScreen from '../screens/VideoCallScreen';

import VideoState from '../screens/VideoCallScreen/context/VideoState';
import VideoTranscript from '../screens/VideoTranscript';
import ViewFile from '../screens/FileExchangeScreen/ViewFile';
import EmailClient from '../screens/EmailClient';
import SecureEmail from '../screens/SecureEmail';
import { useSelector } from 'react-redux';
import { io } from 'socket.io-client';
import ErrorBoundary from '../screens/ErrorBoundary/ErrorBoundary';
import WebsiteFormsScreen from '../screens/WebsiteForms';
// import GroupVideoCallScreen from '../screens/GroupVideoCallScreen';
// import GroupVideoCall from '../screens/GroupVideoCallNew/Index3';
import GroupVideoCallScreen from '../screens/GroupVideoCallScreen';
import SignPDFScreen from '../screens/SignPDF';
// import SampleNotificationScreen from '../components/SampleNotification/SampleNotificationScreen';
// import CreateInsuranceForm from '../screens/FileExchangeScreen/components/CreateInsuranceForm';
// import GroupVideoCallScreen from '../screens/GroupVideoCallScreen';
// import GroupVideoCall from '../screens/GroupVideoCallNew/Index';
import GroupVideoCallSkeleton from '../components/Skeletons/GroupVideoCallSkeleton';
import VideoRoom from '../screens/GroupVideoCallNew/Index';

const Navigation = ({ socket, io }) => {
  const userLogin = useSelector(state => state.userLogin);

 

  return (
    <Router>
      <Route exact path='/login' component={LoginScreen} />
      <Route exact path='/forgot-password' component={ForgotPassword} />

      <VideoState socket={socket}>
        <Route exact path='/' component={FileExchangeScreen} />

        {/* <Route exact path='/samplenotif'>
          <SampleNotificationScreen socket={socket} />
        </Route> */}
        <Route exact path='/secure-files' component={FileExchangeScreen} />
        {/* <Route exact path='/sign-document' component={SignPDFScreen} /> */}
        <Route exact path='/files/:id' component={ViewFile} />
        <Route exact path='/share-file' component={DownloadFileScreen} />

        <Route exact path='/clients' component={ClientsScreen} />
        <Route exact path='/users' component={UsersScreen} />
        <Route exact path='/audit-logs' component={LogsScreen} />
        <Route exact path='/Error' component={ErrorBoundary} />

        {/* <Route exact path='/dynamic-form' component={CreateInsuranceForm} /> */}

        {/* {userLogin &&
          userLogin.userInfo &&
          userLogin?.userInfo?.data &&
          userLogin?.userInfo?.data.user &&
          userLogin?.userInfo?.data.user.modules &&
          userLogin?.userInfo?.data.user.modules.websiteForms && <Route exact path='/website-forms' component={WebsiteFormsScreen} />} */}
        <Route exact path='/website-forms' component={WebsiteFormsScreen} />

        <Fragment>
          <Fragment>
            {
              //   userLogin &&
              //   userLogin.userInfo &&
              //   userLogin.userInfo.data &&
              //   userLogin.userInfo.data.user &&
              //   userLogin.userInfo.data.user.modules &&
              //   userLogin.userInfo.data.user.modules.chat &&
              <Route exact path='/chat'>
                <ChatScreen socket={socket} />
              </Route>
            }

            <Route exact path='/chat-requests'>
              <ChatRequestsScreen socket={socket} />
            </Route>
          </Fragment>

          <Route exact path='/make/:call/:type/:userId/:name/:socketId'>
            <VideoCallScreen socket={socket} />
          </Route>
          {/* <Route exact path='/make/:call/:type/:userId/:name/:socketId'> */}
          {/* <Route exact path='/group-video-call'>
            <GroupVideoCallScreen socket={socket} />
          </Route> */}
          {/* <Route exact path='/group-video-call-new'>
            <GroupVideoCall socket={socket} />
          </Route> */}
          <Route exact path='/group-video-call-test'>
            <VideoRoom
              socket={socket}
              io={io}
            />
            {/* <GroupVideoCallSkeleton /> */}
          </Route>
          <Route exact path='/video-transcript' component={VideoTranscript} />
          <Route exact path='/email'>
            {/* <EmailClient socket={socket} /> */}
            <SecureEmail socket={socket} />
          </Route>
        </Fragment>

        <Route exact path='/my-profile' component={MyProfileScreen} />
      </VideoState>
    </Router>
  );
};

export default Navigation;
