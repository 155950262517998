import React, { useEffect, useRef, useState } from 'react';
import { Device } from 'mediasoup-client';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import RemoteVideo from './VideoSection/RemoteVideo';
import VideoSection from './VideoSection/MainScreen';
import GroupVideoCallSkeleton from '../../components/Skeletons/GroupVideoCallSkeleton';
import Footer from './Footer/Footer';
import Header from './Header/Header';
import { useHistory } from "react-router-dom";
import AddParticipiantModal from '../../components/AddParticipiantModal/AddParticipiantModal';
import toast from 'react-hot-toast';

const mediaType = {
    audio: 'audioType',
    video: 'videoType',
    screen: 'screenType'
};

const VideoRoom = ({ socket, io }) => {
    let { search } = useLocation();
    const history = useHistory();
    const query = new URLSearchParams(search);
    const [roomInfo] = useState(new Map([
        ['roomId', query.get('roomId')],
        ['groupId', query.get('groupId')]
    ]));
    const [isConnected, setIsConnected] = useState(false);
    const [mediaDevices, setMediaDevices] = useState({ audio: [], video: [] });
    const [selectedDevices, setSelectedDevices] = useState({ audio: '', video: '' });
    const [streams, setStreams] = useState({
        local: {
            video: null,
            screen: null,
            isAudioOn: false,
            type: null
        },
        remotes: new Map(),
        isScreenSharing: false
    });
    const [loading, setLoading] = useState(false);
    const [participants, setParticipants] = useState(new Map());
    const [addParticipantInfo, setAddParticipantInfo] = useState({
        modal: false,
        ringingParticipantsIds: [],
        declinedParticipantIds: []
    })

    const [roomDetails, setRoomDetails] = useState({
        userWhoStartedCallName: '',
        totalMembers: 0,
        groupName: '',
        callStartTimestamp: null,
        roomExists: false,
        status: '',
        startedBy: ''
    });

    const socketRef = useRef();
    const producerTransportRef = useRef(null);
    const consumerTransportRef = useRef(null);
    const deviceRef = useRef(null);
    const producersRef = useRef(new Map());
    const consumersRef = useRef(new Map());
    const producerLabelRef = useRef(new Map());

    const localVideoRef = useRef();
    const remoteVideosRef = useRef();

    const userDetails = useSelector((state) => state.userLogin);
    const { onlineUsers } = useSelector((state) => state.onlineUsers);
    const user = userDetails?.userInfo?.data?.user;

    // useEffect(() => {
    //     return () => {
    //         exitRoom(true); // Pass true to indicate offline/unmount exit
    //     };
    // }, []);

    // Effect for local video stream
    useEffect(() => {
        if (localVideoRef.current && streams.local) {
            localVideoRef.current.srcObject = streams.local;
            console.log('Local stream set:', streams.local.id);
        }

        return () => {
            if (localVideoRef.current) {
                localVideoRef.current.srcObject = null;
            }
        };
    }, [streams.local]);

    // Effect for remote video streams
    useEffect(() => {
        const videos = document.querySelectorAll('.remote-video');
        Array.from(streams.remotes.entries()).forEach(([id, stream], index) => {
            if (videos[index]) {
                videos[index].srcObject = stream;
                console.log('Remote stream set:', stream.id);
            }
        });

        return () => {
            videos.forEach(video => {
                if (video) video.srcObject = null;
            });
        };
    }, [streams.remotes]);

    // Socket request wrapper
    const socketRequest = (type, data = {}) => {
        return new Promise((resolve, reject) => {
            if (!socketRef.current) {
                reject('Socket not connected');
                return;
            }
            socketRef.current.emit(type, data, (response) => {
                if (response?.error) {
                    reject(response.error);
                } else {
                    resolve(response);
                }
            });
        });
    };

    const handleInstantParticipantJoined = () => {
        if (socketRef.current) {
            socketRef.current.emit('getMyRoomInfo', null, (roomInfo) => {
                console.log(roomInfo);

                if (roomInfo) {
                    try {
                        const roomData = JSON.parse(roomInfo.peers);
                        console.log({ roomData });

                        const participantsMap = new Map();

                        roomData.forEach(([peerId, peerData]) => {
                            participantsMap.set(peerId, {
                                id: peerId,
                                name: peerData?.name,
                                userId: peerData.userId
                                // hasVideo: false
                            });
                        });

                        setParticipants(participantsMap);
                    } catch (error) {
                        console.error('Error parsing room info:', error);
                    }
                }
            });

            return () => {
                socketRef.current.off('peerJoined');
            };
        }
    }

    useEffect(() => {
        if (!socket) {
            history.push('/chat')
        }
    }, [history, socket])

    useEffect(() => {
        if (!roomInfo.get('roomId') || !user?.name) return;

        if (!socket && io) {
            const newSocket = io.connect(
                process.env.REACT_APP_BASE_SOCKET_URL
                // ,
                // {
                //     query: {
                //         id: user?._id || '',
                //         timezone:
                //             Intl.DateTimeFormat().resolvedOptions()
                //                 .timeZone,
                //     },
                // }
            );
            socketRef.current = newSocket;


        };

        socketRef.current = socket;

        const init = async () => {
            try {
                setLoading(true)
                if (socketRef.current) {
                    initializeSocketListeners();
                    await enumerateDevices();
                    await createRoom();
                    await joinRoom();
                    await handleStartVideo();
                    handleInstantParticipantJoined();
                    setIsConnected(true);
                    setLoading(false);
                    console.log('Room initialized successfully');
                }
            }
            catch (error) {
                console.error('Initialization error:', error);
                history.push('/chat')
                setIsConnected(false);
                deviceRef.current = null;
                producerTransportRef.current = null;
                consumerTransportRef.current = null;
            }
        };

        init();

        return () => {
            exitRoom();
            socketRef.current.off('peerDisconnect');
            socketRef.current.off('peerJoined');
            socketRef.current?.close();
        };
    }, [roomInfo, user?.name])

    const enumerateDevices = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                audio: true,
                video: true
            });

            stream?.getTracks()?.forEach(track => track.stop());

            const devices = await navigator.mediaDevices.enumerateDevices();
            const audioDevices = devices.filter(device => device.kind === 'audioinput');
            const videoDevices = devices.filter(device => device.kind === 'videoinput');

            setMediaDevices({
                audio: audioDevices,
                video: videoDevices
            });

            if (audioDevices.length > 0) {
                setSelectedDevices(prev => ({
                    ...prev,
                    audio: audioDevices[0].deviceId
                }));
            }

            if (videoDevices.length > 0) {
                setSelectedDevices(prev => ({
                    ...prev,
                    video: videoDevices[0].deviceId
                }));
            }

            console.log('Devices enumerated:', {
                audioDevices: audioDevices.length,
                videoDevices: videoDevices.length
            });
        } catch (error) {
            console.error('Error enumerating devices:', error);
            throw error;
        }
    };

    const createRoom = async () => {
        try {
            // Modify the socketRequest to return the callback data
            const response = await socketRequest('createRoom', {
                room_id: roomInfo.get('roomId'),
                group_id: roomInfo.get('groupId'),
                caller_id: user?._id,
                callerName: user?.name,
                onlineUsers: JSON.stringify(onlineUsers)
            });

            setRoomDetails({
                userWhoStartedCallName: response.userWhoStartedCallName,
                totalMembers: response.totalMembers,
                groupName: response.groupName,
                callStartTimestamp: new Date(response.callStartTimestamp),
                roomExists: response.status === 'already exists',
                status: response.status,
                startedBy: response.videoCall?.startedBy
            });

            console.log('Room created successfully:', response);
            return response;
        } catch (error) {
            console.error('Create room error:', error);
            throw error;
        }
    };

    const joinRoom = async () => {
        try {
            const { error, joined, permissionDenied } = await socketRequest('join', {
                name: user?.name || 'TestUser',
                user_id: user?._id,
                room_id: roomInfo.get('roomId'),
                group_id: roomInfo.get('groupId')
            });

            if (permissionDenied) {
                history.push('/')
            }

            if (error) {
                throw new Error(error);
            }

            const rtpCapabilities = await socketRequest('getRouterRtpCapabilities');
            if (!rtpCapabilities) {
                throw new Error('Failed to get RTP capabilities');
            }

            const device = await loadDevice(rtpCapabilities);
            deviceRef.current = device;

            await initTransports(device);
            socketRef.current.emit('getProducers');

            console.log('Successfully joined room and initialized device');
        } catch (error) {
            history.push('/');
            throw error;
        }
    };

    const loadDevice = async (routerRtpCapabilities) => {
        try {
            const device = new Device();
            await device.load({ routerRtpCapabilities });
            return device;
        } catch (error) {
            if (error.name === 'UnsupportedError') {
                console.error('Browser not supported');
                alert('Browser not supported');
            }
            throw error;
        }
    };

    const initTransports = async (device) => {
        try {
            // Producer transport initialization
            const producerTransportData = await socketRequest('createWebRtcTransport', {});

            producerTransportRef.current = device.createSendTransport(producerTransportData);

            producerTransportRef.current.on('connect', async ({ dtlsParameters }, callback, errback) => {
                try {
                    await socketRequest('connectTransport', {
                        dtlsParameters,
                        transport_id: producerTransportData.id
                    });
                    callback();
                } catch (error) {
                    errback(error);
                }
            });

            producerTransportRef.current.on('produce', async ({ kind, rtpParameters, appData }, callback, errback) => {
                console.log({ appData })
                try {
                    const { producer_id } = await socketRequest('produce', {
                        producerTransportId: producerTransportRef.current.id,
                        kind,
                        rtpParameters,
                        isScreen: appData?.isScreen || false
                    });
                    callback({ id: producer_id });
                } catch (error) {
                    errback(error);
                }
            });

            // Add this to your VideoRoom component after creating transports
            producerTransportRef.current.on('connectionstatechange', (state) => {
                console.log('Producer transport connection state:', state);
                if (state === 'failed') {
                    console.error('Producer transport connection failed - trying to restart ICE');
                    // Optionally try to restart ICE
                    socketRequest('restartIce', { transportId: producerTransportRef.current.id })
                        .catch(err => console.error('ICE restart failed:', err));
                }
            });

            const consumerTransportData = await socketRequest('createWebRtcTransport', {});
            consumerTransportRef.current = device.createRecvTransport(consumerTransportData);

            consumerTransportRef.current.on('connect', async ({ dtlsParameters }, callback, errback) => {
                try {
                    await socketRequest('connectTransport', {
                        transport_id: consumerTransportRef.current.id,
                        dtlsParameters
                    });
                    callback();
                } catch (error) {
                    errback(error);
                }
            });

            consumerTransportRef.current.on('connectionstatechange', (state) => {
                console.log('Consumer transport connection state:', state);
                if (state === 'failed') {
                    console.error('Consumer transport connection failed - trying to restart ICE');
                    // Optionally try to restart ICE
                    socketRequest('restartIce', { transportId: consumerTransportRef.current.id })
                        .catch(err => console.error('ICE restart failed:', err));
                }
            });

            console.log('Transports initialized successfully');
        } catch (error) {
            console.error('Transport initialization error:', error);
            throw error;
        }
    };

    const initializeSocketListeners = () => {
        socketRef.current.on('peerJoined', (data) => {
            console.log('🔥 peerJoined event received:', data);
            setParticipants(prev => {
                const newMap = new Map(prev);
                newMap.set(data.socketId, {
                    id: data.socketId,
                    name: data?.name,
                    hasVideo: false
                });
                return newMap;
            });
            toast.success(`${data?.name} joined the call`, {
                position: 'bottom-left',
                icon: '👋',
                style: {
                    background: '#4CAF50',
                    color: '#fff',
                    fontWeight: '600',
                    borderRadius: '2rem',
                    boxShadow: '0 3px 8px rgba(0, 0, 0, 0.12)',
                    // padding: '8px 16px',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0px',
                    fontSize: '12.5px',
                    border: 'none',
                },
                duration: 4000,
            });
            // alert(`${producerName} joined`)
            handleInstantParticipantJoined();
        });

        socketRef.current.on('newProducers', async (producers) => {
            console.log('New producers received:', producers);
            for (const { producer_id } of producers) {
                await consume(producer_id);
            }
        });

        socketRef.current.on('consumerClosed', ({ consumer_id }) => {
            console.log('Consumer closed:', consumer_id);
            removeConsumer(consumer_id);
        });

        socketRef.current.on('disconnect', () => {
            console.log('Socket disconnected');
            exitRoom(true);
        });

        socketRef.current.on('peerDisconnected', ({ socketId }) => {
            if (!socketId) return;

            const disconnectedUser = onlineUsers.find(user => user.socketId === socketId);

            setParticipants((prev) => {
                const newParticipant = new Map(prev);
                if (newParticipant.get(socketId)?.name) {
                    toast(`${newParticipant.get(socketId)?.name} left the call`, {
                        position: 'bottom-left',
                        style: {
                            background: '#EA4335',
                            color: '#fff',
                            fontWeight: '600',
                            borderRadius: '2rem',
                            boxShadow: '0 3px 8px rgba(0, 0, 0, 0.12)',
                            // padding: '12px 16px',
                            display: 'flex',
                            alignItems: 'center',
                            // gap: '8px',
                            fontSize: '1.2rem',
                            border: 'none',
                        },
                        duration: 4000,
                    });
                }
                newParticipant.delete(socketId);
                return newParticipant;
            });

            if (disconnectedUser && disconnectedUser.userId) {
                setAddParticipantInfo(prev => ({
                    ...prev,
                    ringingParticipantsIds: prev.ringingParticipantsIds.filter(id => id !== disconnectedUser.userId)
                }));
            }
        });

        socketRef.current.on('invitationSent', ({ success, participantId }) => {
            if (success) {
                handleAddRingingParticipant(participantId);
            } else {
                console.error('Something went wrong while ringing the call');
            }
        })

        socketRef.current.on('callRejected', ({ from, rejected, msg }) => {
            if (rejected) {
                const foundParticipant = onlineUsers.find((u) => u.socketId === from);
                setAddParticipantInfo((prev) => ({
                    ...prev,
                    ringingParticipantsIds: prev.ringingParticipantsIds?.filter((id) => id !== foundParticipant.userId),
                    declinedParticipantIds: [...prev.declinedParticipantIds, foundParticipant.userId]
                }))
            }
        })

        return () => {
            socketRef.current.off('callRejected');
            socketRef.current.off('invitationSent');
            socketRef.current.off('peerDisconnected');
            socketRef.current.off('disconnect');
            socketRef.current.off('consumerClosed');
            socketRef.current.off('newProducers');
            socketRef.current.off('peerJoined');
        }
    };

    const produce = async (type, deviceId = null) => {
        if (!deviceRef.current || !producerTransportRef.current) {
            console.error('Device or producer transport not initialized');
            return;
        }

        let mediaConstraints = {};
        let audio = false;
        let isScreen = false;
        let stream = null;

        try {
            switch (type) {
                case mediaType.audio:
                    mediaConstraints = {
                        audio: { deviceId },
                        video: false
                    };
                    audio = true;
                    break;
                case mediaType.video:
                    mediaConstraints = {
                        audio: false,
                        video: {
                            deviceId,
                        }
                    };
                    break;
                case mediaType.screen:
                    mediaConstraints = false;
                    isScreen = true;
                    break;
                default:
                    return;
            }

            try {
                stream = isScreen
                    ? await navigator.mediaDevices.getDisplayMedia()
                    : await navigator.mediaDevices.getUserMedia(mediaConstraints);

                // After getting permission for screen share, check and handle existing screen shares
                if (isScreen) {
                    console.log({ streams: streams.remotes });

                    // Check for remote screen shares
                    let remoteScreenConsumerId = null;

                    for (const [streamKey, streamData] of streams.remotes.entries()) {
                        if (streamKey.startsWith('screen-')) {
                            // Find the consumer ID associated with this remote screen share
                            for (const [consumerId, consumerData] of consumersRef.current.entries()) {
                                if (consumerData.isScreen && consumerData.peerId === streamData.peerId) {
                                    remoteScreenConsumerId = consumerId;
                                    break;
                                }
                            }
                            break;
                        }
                    }

                    // Close remote screen share consumer if found
                    if (remoteScreenConsumerId) {
                        console.log('Closing existing remote screen share consumer:', remoteScreenConsumerId);
                        removeConsumer(remoteScreenConsumerId);
                        await new Promise(resolve => setTimeout(resolve, 300));
                    }

                    // Close local screen share if active
                    if (streams.local.screen || streams.isScreenSharing) {
                        closeProducer(mediaType.screen);
                        await new Promise(resolve => setTimeout(resolve, 300));
                    }
                }

            } catch (error) {
                console.error('Error getting media stream:', error);
                throw error;
            }

            const track = audio ? stream.getAudioTracks()[0] : stream.getVideoTracks()[0];

            track.addEventListener('ended', () => {
                closeProducer(type);
            });

            const params = { track };

            if (!audio && !isScreen) {
                params.encodings = [
                    { rid: 'r0', maxBitrate: 100000, scalabilityMode: 'S1T3' },
                    { rid: 'r1', maxBitrate: 300000, scalabilityMode: 'S1T3' },
                    { rid: 'r2', maxBitrate: 900000, scalabilityMode: 'S1T3' }
                ];
                params.codecOptions = { videoGoogleStartBitrate: 1000 };
            }

            const producer = await producerTransportRef.current.produce({
                ...params,
                appData: {
                    isScreen,
                    contentHint: track.contentHint
                }
            });

            producersRef.current.set(producer.id, producer);
            producerLabelRef.current.set(type, producer.id);

            setStreams(prev => {
                const newLocalState = { ...prev.local };

                if (audio) {
                    // For audio
                    newLocalState.audio = new MediaStream([track]);
                    newLocalState.isAudioOn = true;
                } else if (isScreen) {
                    // For screen share
                    newLocalState.screen = new MediaStream([track]);
                } else {
                    // For video
                    newLocalState.video = new MediaStream([track]);
                }

                return {
                    ...prev,
                    local: newLocalState,
                    isScreenSharing: isScreen ? true : prev.isScreenSharing
                };
            });

            return producer;

        } catch (error) {
            console.error('Produce error:', error);
            if (stream) {
                stream.getTracks().forEach(track => track.stop());
            }
            throw error;
        }
    };

    console.log({ streams });

    const consume = async (producer_id) => {
        console.log('Consuming producer:', producer_id);

        try {
            const { rtpCapabilities } = deviceRef.current;
            const data = await socketRequest('consume', {
                rtpCapabilities,
                consumerTransportId: consumerTransportRef.current.id,
                producerId: producer_id
            });

            const { id, kind, rtpParameters, appData, producerName, socketId, peerId } = data;
            console.log('Consumer data received:', { id, kind, appData, producerName, socketId, peerId });

            const consumer = await consumerTransportRef.current.consume({
                id,
                producerId: producer_id,
                kind,
                rtpParameters
            });

            // Very important - store peerId with consumer for reference
            consumer.appData = { peerId, kind };

            const track = consumer.track;

            // IMPORTANT: Always create a separate dedicated audio element for audio tracks
            // regardless of whether video is present
            if (kind === 'audio') {
                console.log('Audio track received for peer:', peerId);

                // Remove any existing audio element for this peer
                const existingAudio = document.getElementById(`audio-${peerId}`);
                if (existingAudio) {
                    existingAudio.remove();
                }

                // Create a new audio element
                const audioElement = document.createElement('audio');
                audioElement.id = `audio-${peerId}`;
                audioElement.autoplay = true;
                audioElement.playsInline = true;
                audioElement.muted = false; // CRUCIAL: Ensure it's not muted

                // Create a dedicated stream just for this audio track
                const audioStream = new MediaStream([track]);
                audioElement.srcObject = audioStream;

                // Add to document and play
                document.body.appendChild(audioElement);

                // Force play with retries
                const playAudio = async () => {
                    try {
                        await audioElement.play();
                        console.log(`Audio playing for peer ${peerId}`);
                    } catch (error) {
                        console.warn(`Failed to play audio for peer ${peerId}, retrying...`, error);
                        setTimeout(playAudio, 1000);
                    }
                };

                playAudio();
            }

            // Add this check after setting content hint for screen sharing
            if (appData && appData.isScreen) {
                consumer.track.contentHint = 'screen';

                // Check if there's an existing screen share producer
                for (const [producerId, producer] of producersRef.current.entries()) {
                    if (producer.appData?.isScreen) {
                        // Stop the existing screen share
                        closeProducer(mediaType.screen);

                        // Update streams state to remove the screen share
                        setStreams(prev => ({
                            ...prev,
                            local: {
                                ...prev.local,
                                screen: null
                            },
                            isScreenSharing: false
                        }));

                        // Break since we only expect one screen share at a time
                        break;
                    }
                }
            }

            // Store consumer with additional metadata
            consumersRef.current.set(consumer.id, {
                consumer,
                kind,
                peerId, // Store peerId with consumer
                isScreen: appData?.isScreen || false
            });

            // Update streams state (but handle audio separately as we did above)
            setStreams(prev => {
                const newRemotes = new Map(prev.remotes);

                // For screen sharing, replace any existing screen share
                if (appData?.isScreen) {
                    // Keep the existing code for screen shares
                    const screenStream = new MediaStream([track]);
                    newRemotes.set(`screen-${peerId}`, {
                        stream: screenStream,
                        metadata: {
                            isScreen: true,
                            kind: kind,
                            socketId: peerId
                        },
                        peerId: peerId,
                        peerName: producerName
                    });

                    // Also update the participant to indicate they're screen sharing
                    setParticipants(prevParticipants => {
                        if (peerId && prevParticipants.has(peerId)) {
                            const newParticipants = new Map(prevParticipants);
                            const existingParticipant = newParticipants.get(peerId);

                            newParticipants.set(peerId, {
                                ...existingParticipant,
                                isScreenSharing: true
                            });
                            return newParticipants;
                        }
                        return prevParticipants;
                    });
                } else if (kind === 'video') { // Only process video tracks here
                    // Use peerId as the key instead of consumer.id
                    const peerStreamKey = `media-${peerId}`;
                    const existingPeerStream = newRemotes.get(peerStreamKey);

                    if (existingPeerStream) {
                        // If we already have a stream for this peer, add the new track to it
                        const existingStream = existingPeerStream.stream;

                        // Remove any existing video tracks
                        existingStream.getVideoTracks().forEach(existingTrack => {
                            existingStream.removeTrack(existingTrack);
                            existingTrack.stop();
                        });

                        // Add the new video track
                        existingStream.addTrack(track);

                        // Update metadata
                        newRemotes.set(peerStreamKey, {
                            ...existingPeerStream,
                            stream: existingStream,
                            metadata: {
                                ...existingPeerStream.metadata,
                                hasVideo: true,
                                lastUpdated: Date.now()
                            },
                            consumerId: consumer.id // Track which consumer added this track
                        });
                    } else {
                        // Create a new stream for this peer's video only
                        const newStream = new MediaStream([track]);
                        newRemotes.set(peerStreamKey, {
                            stream: newStream,
                            metadata: {
                                kind: 'video',
                                hasVideo: true,
                                hasAudio: false, // Audio is handled separately now
                                socketId: peerId,
                                timestamp: Date.now()
                            },
                            peerName: producerName,
                            peerId: peerId,
                            consumerId: consumer.id
                        });
                    }

                    // Also update participants to track video status
                    setParticipants(prevParticipants => {
                        if (peerId && prevParticipants.has(peerId)) {
                            const newParticipants = new Map(prevParticipants);
                            const existingParticipant = newParticipants.get(peerId);

                            newParticipants.set(peerId, {
                                ...existingParticipant,
                                name: producerName || existingParticipant?.name || 'Unknown User',
                                hasVideo: true
                            });
                            return newParticipants;
                        }
                        return prevParticipants;
                    });
                }

                // Update audio status in participants even if we don't add audio to video streams
                if (kind === 'audio') {
                    setParticipants(prevParticipants => {
                        if (peerId && prevParticipants.has(peerId)) {
                            const newParticipants = new Map(prevParticipants);
                            const existingParticipant = newParticipants.get(peerId);

                            newParticipants.set(peerId, {
                                ...existingParticipant,
                                name: producerName || existingParticipant?.name || 'Unknown User',
                                hasAudio: true
                            });
                            return newParticipants;
                        }
                        return prevParticipants;
                    });
                }

                return {
                    ...prev,
                    remotes: newRemotes
                };
            });

            await socketRequest('resume', { consumerId: consumer.id });

            consumer.on('trackended', () => {
                // If this is an audio consumer that's ending, remove the audio element
                if (kind === 'audio') {
                    const audioElement = document.getElementById(`audio-${peerId}`);
                    if (audioElement) {
                        audioElement.remove();
                    }
                }
                removeConsumer(consumer.id);
            });

            consumer.on('transportclose', () => {
                // If this is an audio consumer that's closing, remove the audio element
                if (kind === 'audio') {
                    const audioElement = document.getElementById(`audio-${peerId}`);
                    if (audioElement) {
                        audioElement.remove();
                    }
                }
                removeConsumer(consumer.id);
            });

        } catch (error) {
            console.error('Consume error:', error);
        }
    };

    const closeProducer = (type) => {
        try {
            if (!type) return;
            if (!producerLabelRef.current.has(type)) return;

            const producer_id = producerLabelRef.current.get(type);
            const producer = producersRef.current.get(producer_id);

            if (producer) {
                producer.close();
                producersRef.current.delete(producer_id);
                socketRef.current?.emit('producerClosed', { producer_id });
            }

            producerLabelRef.current.delete(type);

            setStreams(prev => {
                const newLocalState = { ...prev.local };

                // eslint-disable-next-line default-case
                switch (type) {
                    case mediaType.audio:
                        newLocalState.isAudioOn = false;
                        newLocalState.audio = null;
                        break;
                    case mediaType.video:
                        newLocalState.video = null;
                        break;
                    case mediaType.screen:
                        newLocalState.screen = null;
                        break;
                }

                return {
                    ...prev,
                    local: newLocalState,
                    isScreenSharing: type === mediaType.screen ? false : prev.isScreenSharing
                };
            });

        } catch (error) {
            console.error('Error closing producer:', error);
        }
    };

    // const removeConsumer = (consumer_id) => {
    //     consumersRef.current.delete(consumer_id);
    //     setStreams(prev => {
    //         const newRemotes = new Map(prev.remotes);
    //         newRemotes.delete(consumer_id);
    //         return {
    //             ...prev,
    //             remotes: newRemotes
    //         };
    //     });
    //     console.log('Consumer removed:', consumer_id);
    // };

    const removeConsumer = (consumer_id) => {
        const consumerData = consumersRef.current.get(consumer_id);
        console.log(consumerData);
        if (!consumerData) {
            console.log(`Consumer ${consumer_id} not found for removal`);
            return;
        }

        const { peerId, kind, consumer, isScreen: isScreenShare } = consumerData;

        console.log({ peerId })
        // const isScreenShare = consumer?.isScreen;
        consumersRef.current.delete(consumer_id);

        setStreams(prev => {
            const newRemotes = new Map(prev.remotes);

            // Handle differently based on whether it's a screen share or regular media
            if (isScreenShare) {
                // For screen share, remove the screen-specific stream
                const screenKey = `screen-${peerId}`;
                if (newRemotes.has(screenKey)) {
                    const screenStream = newRemotes.get(screenKey).stream;
                    if (screenStream) {
                        screenStream.getTracks().forEach(track => track.stop());
                    }
                    newRemotes.delete(screenKey);

                    // Update participant to indicate screen sharing has stopped
                    setParticipants(prevParticipants => {
                        if (peerId && prevParticipants.has(peerId)) {
                            const newParticipants = new Map(prevParticipants);
                            newParticipants.delete(`screen-${peerId}`);


                            // newParticipants.set(peerId, {
                            //     ...participant,
                            //     isScreenSharing: false
                            // });

                            return newParticipants;
                        }
                        return prevParticipants;
                    });
                }
            } else {
                // For regular media, handle the specific stream
                const peerStreamKey = `media-${peerId}`;
                const peerStream = newRemotes.get(peerStreamKey);

                if (peerStream) {
                    const stream = peerStream.stream;

                    // Only remove tracks of the matching kind
                    const tracksToRemove = [];
                    stream.getTracks().forEach(track => {
                        if (track.kind === kind) {
                            tracksToRemove.push(track);
                        }
                    });

                    tracksToRemove.forEach(track => {
                        stream.removeTrack(track);
                        track.stop();
                    });

                    // If no tracks left, remove the stream
                    if (stream.getTracks().length === 0) {
                        newRemotes.delete(peerStreamKey);
                    } else {
                        // Otherwise update the metadata
                        newRemotes.set(peerStreamKey, {
                            ...peerStream,
                            metadata: {
                                ...peerStream.metadata,
                                hasAudio: stream.getAudioTracks().length > 0,
                                hasVideo: stream.getVideoTracks().length > 0
                            }
                        });
                    }

                    // Update participant status
                        setParticipants(prevParticipants => {
                            if (peerId && prevParticipants.has(peerId)) {
                                const newParticipants = new Map(prevParticipants);
                                const participant = newParticipants.get(peerId);

                                newParticipants.set(peerId, {
                                    ...participant,
                                hasVideo: kind === 'video' ? false : participant.hasVideo,
                                hasAudio: kind === 'audio' ? false : participant.hasAudio
                                });

                                return newParticipants;
                            }
                            return prevParticipants;
                        });
                }
            }

            return {
                ...prev,
                remotes: newRemotes,
                // If we were just showing a screen share and it's gone,
                // make sure isScreenSharing is false
                isScreenSharing: isScreenShare ? false : prev.isScreenSharing
            };
        });

        console.log('Consumer removed:', consumer_id, isScreenShare ? '(screen share)' : `(${kind})`);
    };

    const exitRoom = async (offline = false) => {
        try {
            if (!offline) {
                await socketRequest('exitRoom').catch(console.error);
            }

            // Stop all local streams and clear them
            if (streams.local) {
                if (streams?.local?.video) {
                    streams.local.video.getTracks().forEach(track => track.stop());
                }
                if (streams?.local?.audio) {
                    streams.local.audio.getTracks().forEach(track => track.stop());
                }
                if (streams?.local?.screen) {
                    streams.local.screen.getTracks().forEach(track => track.stop());
                }
            }

            // Close all producers
            producersRef.current.forEach(producer => {
                try {
                    producer.close();
                } catch (e) {
                    console.warn('Error closing producer:', e);
                }
            });
            producersRef.current.clear();
            producerLabelRef.current.clear();

            // Close transports
            if (producerTransportRef.current && !producerTransportRef.current.closed) {
                try {
                    producerTransportRef.current.close();
                } catch (e) {
                    console.warn('Error closing producer transport:', e);
                }
            }

            if (consumerTransportRef.current && !consumerTransportRef.current.closed) {
                try {
                    consumerTransportRef.current.close();
                } catch (e) {
                    console.warn('Error closing consumer transport:', e);
                }
            }

            // Remove socket listeners
            if (socketRef.current) {
                socketRef.current.removeAllListeners();
                // Leave the room in socket
                socketRef.current.emit('leave', { room_id: roomInfo.get('roomId') });
            }

            // Reset all state
            setIsConnected(false);
            setStreams({
                local: {
                    video: null,
                    audio: null,
                    screen: null,
                    isAudioOn: false,
                    type: null
                },
                remotes: new Map(),
                isScreenSharing: false
            });
            setParticipants(new Map());

            // Navigate back
            history.push('/chat');
            window.location.reload();

        } catch (error) {
            console.error('Exit room error:', error);
        }
    };

    const handleStartAudio = async () => {
        if (!isConnected) {
            console.error('Not connected to room');
            return;
        }
        await produce(mediaType.audio, selectedDevices.audio);
    };

    const handleStopAudio = () => closeProducer(mediaType.audio);

    const handleStartVideo = async () => {
        if (!isConnected) {
            console.error('Not connected to room');
            return;
        }
        await produce(mediaType.video, selectedDevices.video)
    }

    const handleStopVideo = () => closeProducer(mediaType.video);

    const handleStartScreen = async () => {
        if (!isConnected) {
            console.error('Not connected to room');
            return;
        }
        await produce(mediaType.screen);
    };

    const handleStopScreen = () => closeProducer(mediaType.screen);

    const handleExit = async () => {
        try {
            await exitRoom();
        } catch (error) {
            console.error('Error during exit:', error);
            // window.location.reload();
        }
    };

    const handleToggleParticipantModal = () => {
        console.log(addParticipantInfo.modal)
        setAddParticipantInfo((prev) => ({
            ...prev,
            modal: !prev.modal,
        }))
    }

    const handleAddRingingParticipant = (participantId) => {
        setAddParticipantInfo((prev) => ({
            ...prev,
            ringingParticipantsIds: [...prev.ringingParticipantsIds, participantId]
        }))
    }

    const handleHangUpRingingCall = (participantId) => {
        setAddParticipantInfo((prev) => ({
            ...prev,
            ringingParticipantsIds: prev.ringingParticipantsIds.filter((id) => id !== participantId)
        }))
    }

    return (
        <div className="video-room">
            {loading ? <GroupVideoCallSkeleton /> : <div className="videos">
                <Header
                    roomDetails={roomDetails}
                    participants={participants.size}
                    currentUserId={user?._id}
                />

                <VideoSection
                    peers={streams.remotes}
                    stream={streams.local}
                    localStream={streams.local}
                    mediaType={mediaType}
                    producerLabelRef={producerLabelRef}
                    isScreenSharing={streams.isScreenSharing}
                    socket={socketRef.current}
                    participants={participants}
                />

                <Footer
                    socket={socket}
                    localStream={streams.local}
                    isScreenSharing={streams.isScreenSharing}
                    mediaType={mediaType}
                    handleStartVideo={handleStartVideo}
                    handleStopVideo={handleStopVideo}
                    handleStopAudio={handleStopAudio}
                    handleStartAudio={handleStartAudio}
                    handleStartScreen={handleStartScreen}
                    handleStopScreen={handleStopScreen}
                    handleExit={handleExit}
                    handleOpenModal={handleToggleParticipantModal}
                />
            </div>}

            <div className="">
                {
                    addParticipantInfo.modal && <AddParticipiantModal
                        onClose={handleToggleParticipantModal}
                        participants={participants}
                        user={user}
                        socketRef={socketRef}
                        roomInfo={roomInfo}
                        participantInfo={addParticipantInfo}
                        handleHangUpRingingCall={handleHangUpRingingCall}
                        setAddParticipantInfo={setAddParticipantInfo}
                        roomDetails={roomDetails}
                        socket={socket}
                    />
                }
            </div>
        </div>
    );
};

export default VideoRoom;