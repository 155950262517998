import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import useApi from '../useApi';

const useGroupParticipants = ({ groupId, autoFetch = true }) => {
    const [participants, setParticipants] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const { makeApiCall, loading } = useApi({
        url: `/group/accepted-pending/users?groupId=${groupId}`,
        method: 'GET',
        params: { groupId }
    });

    const fetchParticipants = async () => {
        if (!groupId) {
            setError('Group ID is required');
            return { success: false, error: 'Group ID is required' };
        }

        setError(null);

        try {
            const { response, error } = await makeApiCall();

            if (response?.status === true) {
                setParticipants(response.data || []);
                return { success: true, data: response.data };
            }

            if (response?.status === false) {
                const errorMessage = response.message || 'Failed to fetch group participants';
                setError(errorMessage);
                toast.error(errorMessage);
                return { success: false, error: errorMessage };
            }

            if (error) {
                setError(error.message || 'An error occurred');
                toast.error(error.message || 'An error occurred');
                return { success: false, error: error.message };
            }
        } catch (err) {
            const errorMessage = err.message || 'An unexpected error occurred';
            setError(errorMessage);
            toast.error(errorMessage);
            return { success: false, error: errorMessage };
        } finally {
            
        }
    };

    useEffect(() => {
        if (autoFetch && groupId) {
            fetchParticipants();
        }
    }, [groupId, autoFetch]);

    return {
        participants,
        isLoading: loading,
        error,
        fetchParticipants,
        refetch: fetchParticipants,
    };
};

export default useGroupParticipants;