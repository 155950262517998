import React, { useEffect, useState } from 'react';
import ChatStyles from '../../Chat.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { getAllGroupDetailsAction, getAllGroupUsersAction } from '../../../../redux/actions/chatActions';
import { useHistory } from 'react-router-dom';
import Button from '../../../../components/Button';
import useGroupDetails from '../../../../hooks/group/useGetGroup';
import generateMeetingId from '../../../../utils/generateMeetingId';

const SelectedGroup = ({ data, setShowGroupInfoMoal, requestVideoCallToSelectedUser }) => {
  const {
    chatUserLoading,
    checkOnlineAndGetData,
    checkOnline,
    selectedNewUser,
    selectedUser,
    setShowSecurityKey,
    showSecurityKey,
    setVideoNotesModal,
    groupName,
    socket,
    group,
    setGroupName,
    groupId,
    videoCallLoading,
    videoCallInfo,
    encryptedSecurityKey,
    encryptMessage
  } = data;

  const [lastSeenTime, setLastSeenTime] = React.useState(0);
  const [subMenu, setSubMenu] = React.useState(false);
  const [groupMembers, setGroupMembers] = React.useState([]);
  const [currentUser, setCurrentUser] = React.useState([]);

  const dispatch = useDispatch();
  const history = useHistory();
  const userLogin = useSelector((state) => state.userLogin);

  useEffect(() => {
    if (selectedNewUser) {
      setGroupName(selectedUser.name);
    }
  }, [selectedNewUser, selectedUser.name, setGroupName]);

  useEffect(() => {
    if (socket) {
      console.log("Socket connected with ID:", socket.id);
    } else {
      console.error("Socket is not initialized.");
    }

    return () => {
      if (socket) {
        socket.off('groupCallAlert');
      }
    };
  }, [socket]);

  React.useEffect(() => {
    if (
      selectedNewUser &&
      Object.keys(selectedNewUser).length > 0 &&
      selectedNewUser.lastSeenTime &&
      selectedNewUser.lastSeenTime > 0
    ) {
      setLastSeenTime(selectedNewUser.lastSeenTime);
    } else if (
      selectedNewUser &&
      Object.keys(selectedNewUser).length === 0 &&
      selectedUser &&
      selectedUser.lastSeenTime &&
      selectedUser.lastSeenTime > 0
    ) {
      setLastSeenTime(selectedUser.lastSeenTime);
    }
  }, [selectedNewUser, selectedUser]);

  const handleGroupCall = () => {
    const roomId = generateMeetingId();

    if (socket && selectedUser && selectedUser.channelID && encryptedSecurityKey?.data?.symmetricKey) {

      const currentUserName = userLogin?.userInfo?.data?.user?.name || "Someone";
      const splitKey = encryptedSecurityKey.data.symmetricKey.split("|");

      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes().toString().padStart(2, '0');
      const ampm = hours >= 12 ? 'PM' : 'AM';
      const formattedHours = hours % 12 || 12;
      const formattedTime = `${formattedHours}:${minutes} ${ampm}`;

      const messageText = `${currentUserName} started a video call 📞 at ${formattedTime}`;
      const encryptedMessage = encryptMessage(
        selectedUser.channelID,
        messageText,
        splitKey[1]
      );

      const emitMessage = {
        chatroomId: selectedUser.channelID,
        message: encryptedMessage,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        keyID: splitKey[1],
        groupId: selectedUser.groupId,
        messageType: selectedUser.type,
        user: selectedUser._id
      };

      socket.emit("chatroomMessage", emitMessage);
    }

    // Navigate to video call page
    history.push(`/group-video-call-test?roomId=${roomId}&groupId=${selectedUser.groupId}`);
  };

  const handleJoinCall = () => {
    if (videoCallInfo?.roomId && selectedUser.groupId) {
      history.push(`/group-video-call-test?roomId=${videoCallInfo.roomId}&groupId=${selectedUser.groupId}`);
    }
  };

  return (
    <div className={ChatStyles.chatBox_user}>
      <div className={ChatStyles.chatBox_user_data}>
        <div className={ChatStyles.chatLists_user_imageBox}>
          <img
            src='/assets/icon-group-logo.jpg'
            alt='User'
            className={ChatStyles.chatLists_user_image}
          />
        </div>
        <div>
          <h3 className={ChatStyles.chatLists_user_name}>
            {groupName || selectedUser.name}
          </h3>
        </div>
      </div>

      {!chatUserLoading && (
        <div className={ChatStyles.chatBox_user_right_container} style={{ gap: '0.4rem' }}>
          {/* Conditional rendering for Join Call or Start Call button */}
          {videoCallInfo?.isActive ? (
            <Button 
              name={'Join Call'}
              style={{
                padding: '1rem 1.4rem',
                margin: '0.3rem 1rem',
                fontSize: '1.2rem',
                fontWeight: '700'
              }}
              onClick={handleJoinCall}
            />
          ) : (
            <button
              onClick={handleGroupCall}
              className={ChatStyles.video_call_button}
            >
              <svg>
                <use xlinkHref="/assets/sprite.svg#icon-video-call" />
              </svg>
            </button>
          )}

          <div
            className='dropdown'
            onMouseOver={() => setSubMenu(!subMenu)}
            onMouseOut={() => setSubMenu(!subMenu)}
          >
            <div className='dropdown-a'>
              <svg>
                <use xlinkHref={`/assets/sprite.svg#icon-three_dots`} />
              </svg>
            </div>
            <input
              type='checkbox'
              checked={subMenu}
              onChange={() => {}}
            />
            <div className='dropdown-c'>
              <ul style={{ width: '15rem' }}>
                <li
                  style={{
                    maxHeight: '50vh',
                    overflowY: 'auto',
                  }}
                >
                  <button
                    className='dropdown_link_btn dropdown_link'
                    onClick={() => setShowGroupInfoMoal(true)}
                  >
                    Group Info
                  </button>
                  {/* <button
                    className='dropdown_link_btn dropdown_link'
                    onClick={() => setVideoNotesModal(true)}
                  >
                    Video Notes
                  </button> */}
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectedGroup;