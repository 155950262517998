import React, { useEffect, useRef, useState } from 'react';
import CustomAvatar from './Avatar';

const RemoteVideo = ({ stream, peerId }) => {
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [hasError, setHasError] = useState(false);
    const streamRef = useRef(stream);
    const lastKnownVisibilityRef = useRef(true);

    useEffect(() => {
        if (stream) {
            streamRef.current = stream;
            setHasError(false);
        }
    }, [stream]);

    useEffect(() => {
        if (!videoRef.current) return;

        const videoElement = videoRef.current;

        const observer = new IntersectionObserver((entries) => {
            const isVisible = entries[0].isIntersecting;

            if (isVisible !== lastKnownVisibilityRef.current) {
                lastKnownVisibilityRef.current = isVisible;

                if (isVisible) {
                    console.log(`Video for peer ${peerId} became visible - reconnecting`);
                    reconnectStream();
                }
            }
        }, { threshold: 0.1 });

        observer.observe(videoElement);

        return () => {
            observer.disconnect();
        };
    }, [peerId]);

    const reconnectStream = async () => {
        if (!videoRef.current || !streamRef.current) return;

        const videoElement = videoRef.current;

        try {
            if (videoElement.srcObject !== streamRef.current) {
                console.log(`Setting srcObject for peer ${peerId}`);
                videoElement.srcObject = streamRef.current;

                try {
                    await videoElement.play();
                    setIsPlaying(true);
                    setHasError(false);
                } catch (playError) {
                    console.warn(`Play failed for peer ${peerId}:`, playError);
                    setIsPlaying(false);
                }
            } else if (videoElement.paused && lastKnownVisibilityRef.current) {
                try {
                    await videoElement.play();
                    setIsPlaying(true);
                } catch (playError) {
                    console.warn(`Play retry failed for peer ${peerId}:`, playError);
                }
            }
        } catch (error) {
            console.error(`Stream reconnection error for peer ${peerId}:`, error);
            setHasError(true);
        }
    };

    useEffect(() => {
        const videoElement = videoRef.current;
        if (!videoElement || !streamRef.current) return;

        console.log(`Initial video setup for peer ${peerId}`);

        const handlePlay = () => {
            console.log(`Video playing for peer ${peerId}`);
            setIsPlaying(true);
        };

        const handlePause = () => {
            console.log(`Video paused for peer ${peerId}`);
            setIsPlaying(false);
        };

        const handleError = (e) => {
            console.error(`Video error for peer ${peerId}:`, e);
            setHasError(true);
            setIsPlaying(false);

            setTimeout(reconnectStream, 1000);
        };

        const handleVisibilityChange = () => {
            if (!document.hidden && lastKnownVisibilityRef.current) {
                console.log(`Tab became visible - checking stream for peer ${peerId}`);
                reconnectStream();
            }
        };

        videoElement.addEventListener('play', handlePlay);
        videoElement.addEventListener('pause', handlePause);
        videoElement.addEventListener('error', handleError);
        document.addEventListener('visibilitychange', handleVisibilityChange);

        reconnectStream();

        return () => {
            videoElement.removeEventListener('play', handlePlay);
            videoElement.removeEventListener('pause', handlePause);
            videoElement.removeEventListener('error', handleError);
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [peerId]);

    return (
        <div style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            // borderRadius: "2rem",
            overflow: "hidden",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#000"
        }}
            data-peer-id={peerId}
            data-playing={isPlaying}
            data-error={hasError}>
            {streamRef.current ? (
                <>
                    <video
                        ref={videoRef}
                        autoPlay
                        playsInline
                        muted
                        style={{
                            width: "100%",
                            height: "100%",
                            objectFit: 'cover',
                            borderRadius: "inherit",
                            transform: 'scaleX(-1)'
                        }}
                    />

                    {hasError && (
                        <div style={{
                            position: "absolute",
                            bottom: "50%",
                            left: "50%",
                            transform: "translate(-50%, 50%)",
                            color: "white",
                            background: "rgba(0,0,0,0.7)",
                            padding: "4px 8px",
                            borderRadius: "4px",
                            fontSize: "12px"
                        }}>
                            Reconnecting...
                        </div>
                    )}
                </>
            ) : (
                <CustomAvatar />
            )}
        </div>
    );
};

export default RemoteVideo;