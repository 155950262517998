import React, { useState, useEffect } from 'react';
import styles from './Header.module.css';

const MeetingHeader = ({
  roomDetails,
  participants = 0,
  groupInitials = "GC",
  currentUserId
}) => {
  const [elapsedTime, setElapsedTime] = useState(0);

  useEffect(() => {
    const startTime = roomDetails.callStartTimestamp ?
      new Date(roomDetails.callStartTimestamp) : new Date();

    const timer = setInterval(() => {
      const now = new Date();
      const diffInSeconds = Math.floor((now - startTime) / 1000);
      setElapsedTime(diffInSeconds);
    }, 1000);

    return () => clearInterval(timer);
  }, [roomDetails.callStartTimestamp]);

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;

    return [
      hours.toString().padStart(2, '0'),
      minutes.toString().padStart(2, '0'),
      secs.toString().padStart(2, '0')
    ].join(':');
  };

  const getGroupInitials = (name) => {
    if (!name) return 'GC';
    const words = name.split(' ');
    if (words.length >= 2) {
      return (words[0][0] + words[1][0]).toUpperCase();
    }
    return words[0].substring(0, 2).toUpperCase();
  };

  const isHost = roomDetails.startedBy === currentUserId;

  return (
    <div className={styles.headerContainer}>
      <div className={styles.leftSection}>
        <div className={styles.groupIcon}>
          <span>{getGroupInitials(roomDetails.groupName) || groupInitials}</span>
        </div>
        <div className={styles.meetingInfo}>
          <h1 className={styles.meetingTitle}>{roomDetails.groupName || "Group Video Call"}</h1>
          <p className={styles.meetingSubtitle}>{
            roomDetails.status === 'created'
              ? "Joined as a host" : "Joined as a partcipant"}</p>
        </div> 
      </div>

      <div className={styles.separator}></div>

      <div className={styles.middleSection}>
        <div className={styles.infoItem}>
          <div className={styles.participantIcon}></div>
          <span>Participants ({participants})</span>
        </div>

        <div className={styles.infoItem}>
          <div className={styles.hostIcon}></div>
          <span>Host: {roomDetails.userWhoStartedCallName || "Unknown user"}{isHost ? " (You)" : ""}</span>
        </div>

        <div className={styles.infoItem}>
          <span>{participants} of {roomDetails.totalMembers || 0} joined</span>
        </div>
      </div>

      <div className={styles.rightSection}>
        <div className={styles.meetingTimer}>
          {formatTime(elapsedTime)}
        </div>
        <div className={styles.connectionStatus}></div>
      </div>
    </div>
  );
};

export default MeetingHeader;