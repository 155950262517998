import React, { useEffect, useState, useCallback } from "react";
import ChatStyles from "../Chat.module.css";
import { shortenWord } from "../../../utils/gernalUtils";
import Button from "../../../components/Button";
import useGroupDetails from "../../../hooks/group/useGetGroup";
import { useHistory } from 'react-router-dom';

const GroupCard = ({ data, setGroupName }) => {
	const {
		user,
		checkOnline,
		newSocketUserMsg,
		setLoadMoreMsg,
		selectedUser,
		setSelectedUser,
		setShowSelectedUser,
		setSelectedNewUser,
		setDecryptedChatData,
		setEncryptedChatData,
		setShowSecurityKey,
		decryptMessageByKeyId,
		socket
	} = data;

	const history = useHistory();

	const [message, setMessage] = useState("fetching messages...");
	const [messageTime, setMessageTime] = useState("");
	const [senderName, setSenderName] = useState("");

	// Pass socket from props to the hook
	const {
		groupDetails,
		videoCallInfo,
		refetch: fetchGroupDetails,
		isLoading: videoCallLoading,
		setVideoCallInfo,
	} = useGroupDetails({
		groupId: user?.groupId,
		socket,
		// No need to implement socket listeners in the hook itself
		autoFetch: true
	});

	useEffect(() => {
		let isMounted = true;

		const getMsg = async () => {
			if (
				user &&
				user.lastMessage &&
				user.lastMessage.messageText !== ""
			) {
				try {
					const msg = await decryptMessageByKeyId(
						user.lastMessage.messageText,
						user.lastMessage.keyID,
						user.channelID,
						user.lastMessage.symmetricKey,
						user.lastMessage.key
					);

					if (isMounted) {
						setMessage(msg);
						if (user.lastMessage.isEncryptedMessage) {
							setSenderName(user.lastMessage.senderName + ": ");
						}

						const messageDate = new Date(
							user.lastMessage.messageTime
						);
						const today = new Date();

						if (messageDate.getDate() === today.getDate()) {
							setMessageTime(
								messageDate
									.toLocaleTimeString()
									.replace(/(.*)\D\d+/, "$1")
							);
						} else if (
							messageDate.getDate() ===
							today.getDate() - 1
						) {
							setMessageTime(
								"Yesterday " +
								messageDate
									.toLocaleTimeString()
									.replace(/(.*)\D\d+/, "$1")
							);
						} else {
							setMessageTime(
								messageDate.toLocaleDateString() +
								" " +
								messageDate
									.toLocaleTimeString()
									.replace(/(.*)\D\d+/, "$1")
							);
						}
					}
				} catch (error) {
					console.error("Error decrypting message:", error);

					if (isMounted) {
						setMessage("");
						setMessageTime("");
					}
				}
			} else {
				if (isMounted) {
					setMessage("");
					setMessageTime("");
				}
			}
		};

		if (user) {
			getMsg();
		}

		// Cleanup function to set the component mount status to false when unmounting
		return () => {
			isMounted = false;
		};
	}, [user, decryptMessageByKeyId, newSocketUserMsg]);

	// Implement socket listeners in GroupCard component
	useEffect(() => {
		if (socket) {
			// Listen for group call initiated events
			socket.on('groupCallAlert', (response) => {
				console.log('Group call initiated:', response);
				if (response.groupId === user.groupId) {
					// Update video call info state
					setVideoCallInfo({
						groupId: response.groupId,
						roomId: response.roomId,
						isActive: true,
						startedBy: response?.senderSocketId
					});
				}
			});

			// Listen for call ended events
			socket.on('callEnded', (data) => {
				console.log('Call ended:', data);
				if (data.groupId === user.groupId) {
					setVideoCallInfo({});
				}
			});
		}

		return () => {
			if (socket) {
				socket.off('groupCallInitiated');
				socket.off('callEnded');
			}
		};
	}, [socket, user.groupId, setVideoCallInfo]);

	const handleGroupSelection = useCallback(() => {
		setEncryptedChatData({});
		setDecryptedChatData([]);
		setSelectedNewUser({});
		setSelectedUser(user);
		setShowSelectedUser(true);
		setLoadMoreMsg(false);
		setShowSecurityKey(false);

		// Set group name when group is selected
		if (setGroupName && typeof setGroupName === 'function') {
			setGroupName(user.name);
		}
	}, [
		user,
		setEncryptedChatData,
		setDecryptedChatData,
		setSelectedNewUser,
		setSelectedUser,
		setShowSelectedUser,
		setLoadMoreMsg,
		setShowSecurityKey,
		setGroupName
	]);

	const handleJoinCall = useCallback((e) => {
		e.stopPropagation(); // Prevent the click from bubbling up to the parent

		if (videoCallInfo?.roomId) {
			history.push(`/group-video-call-test?roomId=${videoCallInfo.roomId}&groupId=${user.groupId}`);
		} else {
			console.error("Incomplete video call information", videoCallInfo);
			// Could add a toast notification here
		}
	}, [videoCallInfo, history, user.groupId]);

	// Simplify the hasActiveCall check
	const hasActiveCall = Boolean(
		videoCallInfo?.isActive &&
		videoCallInfo?.roomId &&
		videoCallInfo?.groupId === user.groupId
	);

	// Only show Join Call button in the group card if this group is NOT selected
	const showJoinCallButton = hasActiveCall && selectedUser._id !== user._id;

	// Debug logs
	console.log('VideoCallInfo:', videoCallInfo);
	console.log('Has active call:', hasActiveCall);
	console.log('Show join call button:', showJoinCallButton);

	return (
		<div className={
			selectedUser._id === user._id
				? [
					ChatStyles.chatLists_user,
					ChatStyles.chatLists_user_current,
				].join(" ")
				: ChatStyles.chatLists_user
		}>
			{/* Clickable area for selecting the group */}
			<div
				className={ChatStyles.chatLists_user_data}
				onClick={handleGroupSelection}
				style={{ cursor: 'pointer' }}
			>
				<div className={ChatStyles.chatLists_user_imageBox}>
					<img
						src="/assets/icon-group-logo.jpg"
						alt="User"
						className={ChatStyles.chatLists_user_image}
					/>
				</div>
				<div>
					<h3 className={ChatStyles.chatLists_user_name}>
						{shortenWord(user.name, 25)}
					</h3>
					{user.unReadMessageCount === 0 ? (
						<p className={ChatStyles.chatLists_user_msg}>
							{message
								? message.length > 20
									? senderName +
									message.slice(0, 35) +
									"..."
									: senderName + message
								: ""}
						</p>
					) : (
						<>
							{!showJoinCallButton && <p className={ChatStyles.chatLists_user_msg_new}>
								{message
									? message.length > 20
										? senderName +
										message.slice(0, 28) +
										"..."
										: senderName + message
									: ""}
								<span style={{ marginLeft: "1rem" }}>
									{user.unReadMessageCount}
								</span>
							</p>}
						</>
					)}
				</div>
			</div>

			<div className={ChatStyles.chatLists_user_actions}>
				{showJoinCallButton && (
					<Button
						name={'Join Call'}
						style={{
							padding: '0.5rem 1rem',
							fontSize: '1.2rem',
							marginTop: '1rem',
							zIndex: '999'  // Ensure button is on top but avoid extreme values
						}}
						onClick={handleJoinCall}
					/>
				)}
				<p className={ChatStyles.chatLists_user_date} onClick={handleGroupSelection}>
					{messageTime}
				</p>
			</div>
		</div>
	);
};

export default GroupCard;