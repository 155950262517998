import React, { useEffect, useRef, useState } from 'react';
import Styles from './VideoSection.module.css';
import RemoteVideo from './RemoteVideo';
import GridLayout from './GridLayout';

const VideoSection = ({
    peers,
    localStream,
    mediaType,
    producerLabelRef,
    isScreenSharing,
    socket,
    participants,
    randomNumber
}) => {
    const [remoteScreenShare, setRemoteScreenShare] = useState(null);
    const [activeStream, setActiveStream] = useState(null);
    const [mainVideoSource, setMainVideoSource] = useState('local');
    const [activeSpeakers, setActiveSpeakers] = useState(new Map());
    const [orderedParticipants, setOrderedParticipants] = useState(new Map());
    const [speakerHistory, setSpeakerHistory] = useState(new Map());
    const [showControls, setShowControls] = useState(true);
    const [isPanelCollapsed, setIsPanelCollapsed] = useState(false);

    // Other ref definitions remain the same
    const userVideoRef = useRef(null);
    const localVideoRef = useRef(null);
    const sharedScreenRef = useRef(null);
    const audioAnalysersRef = useRef(new Map());
    const activityCheckIntervalRef = useRef(null);

    const lastSpeakingTimesRef = useRef(new Map());
    const deactivationDelayMs = 300;
    const minSpeakingLevelRef = useRef(3);
    const speakingThreshold = useRef(5);

    const togglePanel = () => {
        setIsPanelCollapsed(!isPanelCollapsed);
    };

    useEffect(() => {
        let timeout;

        const handleMouseMove = () => {
            setShowControls(true);
            clearTimeout(timeout);

            timeout = setTimeout(() => {
                setShowControls(false);
            }, 3000);
        };

        const mainVideo = document.querySelector(`.${Styles.userVideoContainer}`);
        if (mainVideo) {
            mainVideo.addEventListener('mousemove', handleMouseMove);
        }

        // Initialize with visible controls
        handleMouseMove();

        return () => {
            if (mainVideo) {
                mainVideo.removeEventListener('mousemove', handleMouseMove);
            }
            clearTimeout(timeout);
        };
    }, []);

    useEffect(() => {
        if (!peers || peers.size === 0) return;

        audioAnalysersRef.current.forEach((analyzer) => {
            analyzer.disconnect();
        });
        audioAnalysersRef.current.clear();

        const setupAudioAnalysis = (participantId, audioStream) => {
            try {
                if (audioAnalysersRef.current.has(participantId)) {
                    audioAnalysersRef.current.get(participantId).disconnect();
                }

                const audioContext = new (window.AudioContext || window.webkitAudioContext)();
                const analyzer = audioContext.createAnalyser();
                analyzer.fftSize = 256;
                const bufferLength = analyzer.frequencyBinCount;
                const dataArray = new Uint8Array(bufferLength);

                const source = audioContext.createMediaStreamSource(audioStream);
                source.connect(analyzer);

                audioAnalysersRef.current.set(participantId, {
                    analyzer,
                    dataArray,
                    disconnect: () => {
                        source.disconnect();
                        analyzer.disconnect();
                    }
                });

                console.log(`Set up audio analysis for participant ${participantId}`);
            } catch (error) {
                console.error(`Error setting up audio analysis for ${participantId}:`, error);
            }
        };

        Array.from(peers.entries()).forEach(([peerId, streamData]) => {
            const { stream } = streamData;
            if (streamData.metadata?.isScreen) return;
            if (stream && stream.getAudioTracks().length > 0) {
                const audioStream = new MediaStream(stream.getAudioTracks());
                setupAudioAnalysis(peerId, audioStream);
            }
        });

        Array.from(participants.entries()).forEach(([participantId]) => {
            const audioElement = document.getElementById(`audio-${participantId}`);
            if (audioElement && audioElement.srcObject) {
                setupAudioAnalysis(participantId, audioElement.srcObject);
            }
        });

        if (activityCheckIntervalRef.current) {
            clearInterval(activityCheckIntervalRef.current);
        }

        activityCheckIntervalRef.current = setInterval(() => {
            const now = Date.now();
            const updatedActiveSpeakers = new Map(activeSpeakers);
            let changed = false;
            let speakerHistoryChanged = false;
            const updatedSpeakerHistory = new Map(speakerHistory);

            audioAnalysersRef.current.forEach((analyzerData, participantId) => {
                const { analyzer, dataArray } = analyzerData;
                analyzer.getByteFrequencyData(dataArray);

                let sum = 0;
                for (let i = 0; i < dataArray.length; i++) {
                    sum += dataArray[i];
                }
                const average = sum / dataArray.length;

                if (average > speakingThreshold.current) {
                    lastSpeakingTimesRef.current.set(participantId, now);

                    if (!updatedActiveSpeakers.has(participantId)) {
                        updatedActiveSpeakers.set(participantId, now);
                        changed = true;
                        console.log(`Participant ${participantId} is speaking (level: ${average.toFixed(2)})`);
                    }

                    if (!updatedSpeakerHistory.has(participantId)) {
                        updatedSpeakerHistory.set(participantId, now);
                        speakerHistoryChanged = true;
                    }
                }
                else if (average < minSpeakingLevelRef.current && updatedActiveSpeakers.has(participantId)) {
                    const lastSpoke = lastSpeakingTimesRef.current.get(participantId) || 0;
                    if (now - lastSpoke > deactivationDelayMs) {
                        updatedActiveSpeakers.delete(participantId);
                        changed = true;
                        console.log(`Participant ${participantId} stopped speaking`);
                    }
                }
            });

            if (changed) {
                setActiveSpeakers(updatedActiveSpeakers);
            }

            if (speakerHistoryChanged) {
                setSpeakerHistory(updatedSpeakerHistory);
                updateParticipantOrder(updatedActiveSpeakers, updatedSpeakerHistory);
            }
        }, 150);

        return () => {
            if (activityCheckIntervalRef.current) {
                clearInterval(activityCheckIntervalRef.current);
            }

            audioAnalysersRef.current.forEach((analyzer) => {
                analyzer.disconnect();
            });
            audioAnalysersRef.current.clear();
        };
    }, [peers, participants]);

    useEffect(() => {
        document.querySelectorAll(`.${Styles.participantContainer}`).forEach(container => {
            const keyAttr = container.getAttribute('data-peer-id');
            if (keyAttr) {
                if (activeSpeakers.has(keyAttr)) {
                    if (!container.classList.contains(Styles.activeSpeaker)) {
                        container.classList.add(Styles.activeSpeaker);
                    }
                } else {
                    container.classList.remove(Styles.activeSpeaker);
                }
            }
        });
    }, [activeSpeakers]);

    useEffect(() => {
        if (isScreenSharing && localStream?.screen) {
            setRemoteScreenShare(null);
            setActiveStream(localStream.screen);
            setMainVideoSource('local-screen');
            return;
        }

        let foundScreenShare = null;
        for (const [peerId, streamData] of peers.entries()) {
            const { stream, metadata } = streamData;
            if (metadata?.isScreen) {
                foundScreenShare = { peerId, stream };
                break;
            }
        }

        if (foundScreenShare) {
            setRemoteScreenShare(foundScreenShare);
            setMainVideoSource('remote-screen');
        } else {
            if (localStream?.video) {
                setActiveStream(localStream.video);
                setMainVideoSource('local');
            }
            setRemoteScreenShare(null);
        }
    }, [peers, isScreenSharing, localStream]);

    useEffect(() => {
        if (mainVideoSource === 'local-screen' && userVideoRef.current && localStream?.screen) {
            userVideoRef.current.srcObject = localStream.screen;
            userVideoRef.current.play().catch(console.error);
        } else if (mainVideoSource === 'local' && userVideoRef.current && localStream?.video) {
            userVideoRef.current.srcObject = localStream.video;
            userVideoRef.current.play().catch(console.error);
        }

        // Handle local video overlay
        if (localVideoRef.current && localStream?.video) {
            localVideoRef.current.srcObject = localStream.video;
            localVideoRef.current.play().catch(console.error);
        }

        if (sharedScreenRef.current && remoteScreenShare?.stream) {
            sharedScreenRef.current.srcObject = remoteScreenShare.stream;
            sharedScreenRef.current.play().catch(console.error);
        }

        return () => {
            [userVideoRef, localVideoRef, sharedScreenRef].forEach(ref => {
                if (ref.current) {
                    ref.current.srcObject = null;
                }
            });
        };
    }, [activeStream, localStream, remoteScreenShare, mainVideoSource]);

    useEffect(() => {
        Array.from(peers.entries()).forEach(([peerId, streamData]) => {
            const { stream } = streamData;
            if (stream.getAudioTracks().length > 0) {
                let audioElement = document.getElementById(`audio-${peerId}`);
                if (!audioElement) {
                    audioElement = document.createElement('audio');
                    audioElement.id = `audio-${peerId}`;
                    audioElement.autoplay = true;
                    audioElement.playsInline = true;
                    document.body.appendChild(audioElement);
                }
                audioElement.srcObject = stream;
            }
        });

        return () => {
            Array.from(peers.entries()).forEach(([peerId]) => {
                const audioElement = document.getElementById(`audio-${peerId}`);
                if (audioElement) {
                    audioElement.remove();
                }
            });
        };
    }, [peers]);

    useEffect(() => {
        if (participants.size > 0) {
            updateParticipantOrder(activeSpeakers, speakerHistory);
        }
    }, [participants]);

    const updateParticipantOrder = (speakers, history = speakerHistory) => {
        if (participants.size === 0) return;

        const sortedParticipantIds = Array.from(participants.keys()).sort((a, b) => {
            const aIsScreenSharing = peers && Array.from(peers.entries()).some(
                ([_, data]) => data.metadata?.isScreen && (data.metadata?.socketId === a || data.peerId === a)
            );
            const bIsScreenSharing = peers && Array.from(peers.entries()).some(
                ([_, data]) => data.metadata?.isScreen && (data.metadata?.socketId === b || data.peerId === b)
            );

            if (aIsScreenSharing && !bIsScreenSharing) return -1;
            if (!aIsScreenSharing && bIsScreenSharing) return 1;

            const aIsSpeaking = speakers.has(a);
            const bIsSpeaking = speakers.has(b);

            if (aIsSpeaking && !bIsSpeaking) return -1;
            if (!aIsSpeaking && bIsSpeaking) return 1;

            const aInHistory = history.has(a);
            const bInHistory = history.has(b);

            if (aInHistory && !bInHistory) return -1;
            if (!aInHistory && bInHistory) return 1;

            if (aInHistory && bInHistory) {
                const aTime = history.get(a) || 0;
                const bTime = history.get(b) || 0;
                return bTime - aTime;
            }

            return 0;
        });

        const newOrderedParticipants = new Map();
        sortedParticipantIds.forEach(id => {
            if (participants.has(id)) {
                newOrderedParticipants.set(id, participants.get(id));
            }
        });

        setOrderedParticipants(newOrderedParticipants);
        console.log("Updated participant order:", Array.from(newOrderedParticipants.keys()));
    };

    return (
        <div className={`${Styles.videoScreen} ${isPanelCollapsed ? Styles.panelCollapsed : ''}`}>
            {/* Toggle Button for Panel */}
            <div className={Styles.panelToggleButton} onClick={togglePanel}>
                <svg viewBox="0 0 24 24" width="24" height="24" className={isPanelCollapsed ? Styles.iconRotated : ''}>
                    <path fill="currentColor" d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
                </svg>
            </div>

            <div className={Styles.paticipantsScreen}>
                {renderParticipantVideos()?.length > 0 ? (
                    <GridLayout items={renderParticipantVideos()} />
                ) : (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: 'calc(100% - 11rem)',
                        color: 'rgba(255,255,255,0.5)',
                        textAlign: 'center',
                        padding: '2rem'
                    }}>
                        <div>
                            <div style={{ fontSize: '2.5rem', marginBottom: '1rem' }}>👋</div>
                            <div>Waiting for others to join...</div>
                        </div>
                    </div>
                )}

                {shouldShowLocalVideo() && (
                    <div className={Styles.localVideoWrapper} style={{
                        minHeight: `${participants.size > 3 ? "28%" : "37%"}`
                    }}>
                        {hasEnabledVideo() ? (
                            <video
                                ref={localVideoRef}
                                autoPlay
                                muted
                                playsInline
                                className={Styles.localVideoOverlay}
                                style={{ transform: 'scaleX(-1)' }}
                            />
                        ) : (
                            <div className={`${Styles.fallbackVideo}`}>
                                <div className={Styles.fallbackContent}>
                                    <div className={Styles.fallbackInitial}>
                                        You
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className={Styles.localVideoName}>
                            You
                        </div>
                        <div className={Styles.mediaDevicesContainer}>
                            <button style={{ position: 'relative' }}>
                                <svg className={Styles.mediaDevice}>
                                    <use xlinkHref={`/assets/sprite.svg#video-button`} />
                                </svg>
                                {!hasEnabledVideo() && (
                                    <div className={Styles.crossLine}></div>
                                )}
                            </button>
                            <button style={{ position: 'relative' }}>
                                <svg className={Styles.mediaDevice}>
                                    <use xlinkHref={`/assets/sprite.svg#microphone-button`} />
                                </svg>
                                {!hasEnabledAudio() && (
                                    <div className={Styles.crossLine}></div>
                                )}
                            </button>
                        </div>
                    </div>
                )}
            </div>

            {/* Main video area */}
            <div className={Styles.userVideoContainer}>
                {renderMainVideo()}
            </div>
        </div>
    );

    // Check if video tracks exist AND are enabled
    function hasEnabledVideo() {
        return localStream?.video &&
            localStream.video.getVideoTracks().length > 0 &&
            localStream.video.getVideoTracks()[0].enabled;
    }

    // Check if audio tracks exist AND are enabled
    function hasEnabledAudio() {
        return localStream?.audio &&
            localStream.audio.getAudioTracks().length > 0 &&
            localStream.audio.getAudioTracks()[0].enabled;
    }

    function renderParticipantVideos() {
        if (!participants || participants.size === 0) {
            console.log("No participants found");
            return [];
        }

        const mySocketId = socket?.id;
        const participantsToRender = orderedParticipants.size > 0 ? orderedParticipants : participants;

        // Create a map of peerId to streamData for easy lookup
        const participantStreamsMap = new Map();

        peers.forEach((peerData, peerId) => {
            const participantId = peerData.metadata?.socketId || peerData.peerId;

            if (participantId) {
                if (peerData.metadata?.isScreen) {
                    participantStreamsMap.set(`screen-${participantId}`, peerData);
                } else {
                    participantStreamsMap.set(participantId, peerData);
                }
            }
        });

        // Also track who is screen sharing in a separate map for sorting
        const screenSharingParticipants = new Set();
        Array.from(peers.entries()).forEach(([_, peerData]) => {
            if (peerData.metadata?.isScreen) {
                const screenSharerId = peerData.metadata?.socketId || peerData.peerId;
                if (screenSharerId) {
                    screenSharingParticipants.add(screenSharerId);
                }
            }
        });

        return Array.from(participantsToRender.entries())
            .filter(([participantId]) => participantId !== mySocketId)
            .map(([participantId, participantData]) => {
                // Get the regular media stream for this participant
                const mediaStreamData = participantStreamsMap.get(participantId);
                // Also check if they have a screen share stream
                const screenStreamData = participantStreamsMap.get(`screen-${participantId}`);

                let hasVideo = false;
                let streamToUse = null;

                // Prioritize using the regular video stream for display
                if (mediaStreamData?.stream) {
                    const videoTracks = mediaStreamData.stream.getVideoTracks();
                    hasVideo = videoTracks && videoTracks.length > 0 && videoTracks[0].enabled;
                    streamToUse = mediaStreamData.stream;
                }

                // Check for audio element
                const audioElement = document.getElementById(`audio-${participantId}`);

                // Check participant data for hasAudio property
                const hasAudioFromParticipantData = participantData.hasAudio;

                // Final determination of audio status
                // let hasAudio = hasAudioFromParticipantData && !!audioElement;

                // if (audioElement && audioElement.srcObject) {
                //     const audioTracks = audioElement.srcObject.getAudioTracks();
                //     if (audioTracks.length === 0 || !audioTracks[0].enabled) {
                //         hasAudio = false;
                //     }
                // }

                let hasAudio = participantData.hasAudio === true;

                // Only check the audio element if participant data says audio should be enabled
                // This ensures mute state from participants Map takes precedence
                if (hasAudio && audioElement && audioElement.srcObject) {
                    const audioTracks = audioElement.srcObject.getAudioTracks();
                    if (audioTracks.length === 0 || !audioTracks[0].enabled) {
                        hasAudio = false;
                    }
                }

                // Check if this participant is currently speaking
                const isSpeaking = activeSpeakers.has(participantId);

                // Check if this participant is screen sharing
                const isScreenSharing = !!screenStreamData || screenSharingParticipants.has(participantId);

                // Check if in speaker history
                const isInSpeakerHistory = speakerHistory.has(participantId);

                // Determine name from available data
                const participantName = participantData?.name ||
                    mediaStreamData?.peerName ||
                    screenStreamData?.peerName ||
                    'Unknown User';

                // Add more visual improvements for speaking and screen sharing indicators
                return (
                    <div
                        key={`video-container-${participantId}`}
                        data-peer-id={participantId}
                        data-is-speaking={isSpeaking}
                        data-is-sharing={isScreenSharing}
                        data-in-history={isInSpeakerHistory}
                        className={`${Styles.participantContainer} ${isSpeaking ? Styles.activeSpeaker : ''} ${isScreenSharing ? Styles.screenSharer : ''}`}
                    >
                        {hasVideo ? (
                            <RemoteVideo
                                key={`video-${participantId}`}
                                stream={streamToUse}
                                peerId={participantId}
                            />
                        ) : (
                            <div className={Styles.fallbackVideo}>
                                <div className={Styles.fallbackContent}>
                                    <div className={Styles.fallbackInitial}>
                                        {(participantName && participantName.charAt(0)) || '?'}
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* Name with status indicators */}
                        <div className={Styles.participantName}>
                            {participantName}
                            {isSpeaking && (
                                <span style={{
                                    marginLeft: '0.4rem',
                                    display: 'inline-block',
                                    position: 'relative',
                                    top: '0.15rem'
                                }}>
                                    <svg width="12" height="12" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 2a1 1 0 0 1 1 1v18a1 1 0 1 1-2 0V3a1 1 0 0 1 1-1z" fill="#4caf50" />
                                        <path d="M16 6a1 1 0 0 1 1 1v10a1 1 0 1 1-2 0V7a1 1 0 0 1 1-1z" fill="#4caf50" />
                                        <path d="M8 6a1 1 0 0 1 1 1v10a1 1 0 1 1-2 0V7a1 1 0 0 1 1-1z" fill="#4caf50" />
                                        <path d="M20 10a1 1 0 0 1 1 1v2a1 1 0 1 1-2 0v-2a1 1 0 0 1 1-1z" fill="#4caf50" />
                                        <path d="M4 10a1 1 0 0 1 1 1v2a1 1 0 1 1-2 0v-2a1 1 0 0 1 1-1z" fill="#4caf50" />
                                    </svg>
                                </span>
                            )}
                            {isScreenSharing && (
                                <span style={{
                                    marginLeft: '0.4rem',
                                    display: 'inline-block',
                                    position: 'relative',
                                    top: '0.15rem'
                                }}>
                                    (Sharing)
                                </span>
                            )}
                        </div>

                        <div className={Styles.mediaDevicesContainer}>
                            <button style={{ position: 'relative' }}>
                                <svg className={Styles.mediaDevice}>
                                    <use xlinkHref={`/assets/sprite.svg#video-button`} />
                                </svg>
                                {!hasVideo && <div className={Styles.crossLine}></div>}
                            </button>
                            <button style={{ position: 'relative' }}>
                                <svg className={Styles.mediaDevice}>
                                    <use xlinkHref={`/assets/sprite.svg#microphone-button`} />
                                </svg>
                                {!hasAudio && <div className={Styles.crossLine}></div>}
                            </button>
                        </div>
                    </div>
                );
            });
    }

    function shouldShowLocalVideo() {
        // Always show the local video container during screen sharing
        // regardless of whether video is enabled or not
        return (isScreenSharing || remoteScreenShare?.stream);
    }

    function renderMainVideo() {
        const showFallback = () => (
            <div className={`${Styles.mainVideoContainer} ${Styles.fallbackMainVideo}`}>
                <div className={Styles.fallbackContent}>
                    <div className={Styles.fallbackInitial}>
                        You
                    </div>
                </div>
            </div>
        );

        // Case 1: No streams available at all
        if (!activeStream && !remoteScreenShare?.stream && !localStream?.video) {
            return showFallback();
        }

        // Case 2: Local screen share
        if (mainVideoSource === 'local-screen' && localStream?.screen) {
            return (
                <div className={Styles.mainVideoContainer}>
                    <video
                        ref={userVideoRef}
                        autoPlay
                        muted
                        playsInline
                        className={Styles.userVideo}
                    />
                    <div className={Styles.mainVideoName}>
                        You (Screen Share)
                    </div>
                </div>
            );
        }

        // Case 3: Remote screen share
        if (mainVideoSource === 'remote-screen' && remoteScreenShare?.stream) {
            const peerData = Array.from(peers.entries())
                .find(([peerId, data]) => peerId === remoteScreenShare.peerId)?.[1];
            const peerName = peerData?.peerName || 'Unknown User';

            return (
                <div className={Styles.mainVideoContainer}>
                    <video
                        ref={sharedScreenRef}
                        autoPlay
                        playsInline
                        className={Styles.userVideo}
                    />
                    <div className={Styles.mainVideoName}>
                        {peerName} (Screen Share)
                    </div>
                </div>
            );
        }

        // Case 4: Local video (no active speaker as main)
        if (localStream?.video) {
            if (!hasEnabledVideo()) {
                return showFallback();
            }
            return (
                <div className={Styles.mainVideoContainer}>
                    <video
                        ref={userVideoRef}
                        autoPlay
                        muted
                        playsInline
                        className={Styles.userVideo}
                        style={{ transform: 'scaleX(-1)' }}
                    />
                    <div className={Styles.mainVideoName}>
                        You
                    </div>
                </div>
            );
        }

        return showFallback();
    }
};

export default VideoSection;