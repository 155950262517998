import React from 'react';
import Styles from './Footer.module.css';

const Footer = ({
  localStream,
  mediaType,
  handleStartVideo,
  handleStopVideo,
  handleStartAudio,
  handleStopAudio,
  handleStartScreen,
  handleStopScreen,
  handleExit,
  handleOpenModal
}) => {
  const handleMediaToggle = (e, type) => {
    e.preventDefault();

    switch (type) {
      case mediaType.video:
        if (localStream.video) {
          handleStopVideo();
        } else {
          handleStartVideo();
        }
        break;

      case mediaType.audio:
        if (localStream.isAudioOn) {
          handleStopAudio();
        } else {
          handleStartAudio();
        }
        break;
      default:
        break;
    }
  };

  const handleToggleScreenSharing = async (e) => {
    e.preventDefault();
    if (localStream.screen) {
      handleStopScreen();
    } else {
      handleStartScreen();
    }
  };

  return (
    <div className={Styles.groupVideoCall}>
      <div className={Styles.footerInfo}>
        {/* asdaaaaaaaaa */}
      </div>

      <div className={Styles.centerButtons}>
        <button
          className={`${Styles.videoButton} ${localStream.video ? Styles.bgBlue : Styles.bgGrey}`}
          onClick={(e) => handleMediaToggle(e, mediaType.video)}
          title={localStream.video ? "Turn off camera" : "Turn on camera"}
        >
          <svg className={Styles.buttonIcon}>
            <use xlinkHref={`/assets/sprite.svg#video-button`} />
          </svg>
          {!localStream.video && <div className={Styles.crossLine}></div>}
        </button>

        <button
          className={`${Styles.videoButton} ${localStream.isAudioOn ? Styles.bgBlue : Styles.bgGrey}`}
          onClick={(e) => handleMediaToggle(e, mediaType.audio)}
          title={localStream.isAudioOn ? "Mute microphone" : "Unmute microphone"}
        >
          <svg className={Styles.buttonIcon}>
            <use xlinkHref={`/assets/sprite.svg#microphone-button`} />
          </svg>
          {!localStream.isAudioOn && <div className={Styles.crossLine}></div>}
        </button>
        
        <button 
          className={Styles.callEndButton} 
          onClick={handleExit}
          title="End call"
        >
          <svg className={Styles.buttonIcon}>
            <use xlinkHref={`/assets/sprite.svg#icon-call-end-button`} />
          </svg>
        </button>
      </div>

      <div className={Styles.footerLeft}>
        <div 
          className={Styles.miniPlayerButton} 
          onClick={handleOpenModal}
          title="Add participants"
        >
          <svg className={Styles.miniPlayerIcon}>
            <use xlinkHref={`/assets/sprite.svg#icon-addParticipants`} />
          </svg>
          <span className={Styles.addParticipantsIcon}>
            Add Participants
          </span>
        </div>
        
        <div 
          className={`${Styles.miniPlayerButton} ${localStream.screen ? Styles.active : ''}`}
          onClick={(e) => handleToggleScreenSharing(e)}
          title={localStream.screen ? "Stop sharing screen" : "Share your screen"}
        >
          <svg className={Styles.miniPlayerIcon}>
            <use xlinkHref={localStream.screen ? 
              `/assets/sprite.svg#icon-stopScreenShare` : 
              `/assets/sprite.svg#icon-screenShare`} 
            />
          </svg>
          <span className={Styles.screenShareIcon}>
            {localStream.screen ? 'Stop Sharing' : 'Share Screen'}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Footer;