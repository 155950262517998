import React, { Fragment, useEffect, useState } from "react";
import CryptoJS from "crypto-js";
import Picker from "emoji-picker-react";
import toast from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";

import { useDispatch, useSelector } from "react-redux";
import {
	createSecurityKeyAction,
	getAllChatUserAction,
	getAllChatUserActionByNumber,
	getAllSecurityKeyAction,
	getChatsAction,
	getSecurityKeyAction,
	getSecurityKeyByIdAction,
	makeDefaultSecurityKeyAction,
} from "../../redux/actions/chatActions";
import {
	createVideoNotesAction,
	deleteVideoNotesAction,
	getAllVideoNotesAction,
	updateVideoNotesAction,
} from "../../redux/actions/videoNoteActions";

import UserComp from "./User/UserComp";
import GroupCard from "./User/GroupCard";
import NoData from "../../components/NoData";
import Content from "../../components/Content";
import NewSecurityKeyModal from "./NewSecurityKeyModal";
import ChatUserSkeleton from "../../components/Skeletons/ChatUserSkeleton";
import ChatsSkeleton from "../../components/Skeletons/ChatsSkeleton";
import ChatStyles from "./Chat.module.css";

import TabLinks from "./User/TabLinks";
import SearchBox from "./User/SearchBox";
import SelectedUser from "./GroupChatScreen/Chat/SelectedUser";
// import VideoContext from '../VideoCallScreen/context/VideoContext';
import { useHistory } from "react-router-dom";
import VideoNotesModal from "./VideoNotesModal";
import DeleteVideoNoteModal from "./DeleteVideoNoteModal";
import AddVideoNoteModal from "./AddVideoNoteModal";
import AddGroupUsersMoal from "./GroupChatScreen/AddGroupUsersModal/AddGroupUsersMoal";
import GroupInfoModal from "./GroupChatScreen/GroupInfoModal/GroupInfoModal";
import SelectedGroup from "./GroupChatScreen/Chat/SelectedGroup";
import SuccessToast from "../../components/Toast/SuccessToast";
import ErrorToast from "../../components/Toast/ErrorToast";
import ChatboxPlaceholder from "./ChatboxPlaceholder";
import useGroupDetails from "../../hooks/group/useGetGroup";

const ChatScreen = ({ socket }) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const messagesStartRef = React.useRef(null);
	const messagesEndRef = React.useRef(null);
	const [lastTopMessage, setLastTopMessage] = React.useState({});
	// const {
	// 	call,
	// 	callAccepted,
	// 	myVideo,
	// 	userVideo,
	// 	stream,
	// 	name,
	// 	setName,
	// 	callEnded,
	// 	me,
	// 	callUser,
	// 	leaveCall,
	// 	answerCall,
	// 	otherUser,
	// 	setOtherUser,
	// 	leaveCall1
	// } = useContext(VideoContext);
	const [acceptedUserLoading, setAcceptedUserLoading] = React.useState(false);
	const [groupUsersLoading, setgroupUsersLoading] = React.useState(false);
	const [allUsersLoading, setAllUsersLoading] = React.useState(false);
	const [showAddGroupMoal, setShowAddGroupMoal] = React.useState(false);
	const [showGroupInfoMoal, setShowGroupInfoMoal] = React.useState(false);
	const [acceptedUsers, setAcceptedUsers] = React.useState({});
	const [allUsers, setAllUsers] = React.useState([]);
	const [groupUsers, setGroupUsers] = React.useState({});
	const [selectedUser, setSelectedUser] = React.useState({});
	const [showSelectedUser, setShowSelectedUser] = React.useState(false);
	const [selectedNewUser, setSelectedNewUser] = React.useState({});
	const [acceptedUserSearch, setAcceptedUserSearch] = React.useState("");

	const [chatUserLoading, setChatUserLoading] = React.useState(false);
	const [chatSendLoading, setChatSendLoading] = React.useState(false);
	const [showEmojiBox, setShowEmojiBox] = React.useState(false);

	const [showErrorToast, setShowErrorToast] = useState(false);
	const [showSuccessToast, setShowSuccessToast] = useState(false);
	const [toastMessage, setToastMessage] = useState("");

	const [showReactBox, setShowReactBox] = React.useState({
		status: false,
		chat: {},
	});
	const [removedGroupId, setRemovedGroupId] = React.useState("");
	const [removedGroup, setRemovedGroup] = React.useState(false);
	const [selectedMessage, setSelectedMessage] = React.useState({});
	const [encryptedSecurityKey, setEncryptedSecurityKey] = React.useState({});
	const [decryptedSecurityKey, setDecryptedSecurityKey] = React.useState({});
	const [encryptedChatData, setEncryptedChatData] = React.useState({});
	const [decryptedChatData, setDecryptedChatData] = React.useState([]);
	const [groupedData, setGroupedData] = React.useState({});
	const [groupName, setGroupName] = React.useState();
	const [groupId, setGroupId] = React.useState();

	const [chatText, setChatText] = React.useState({ value: "", error: false });

	const [securityKeys, setSecurityKeys] = React.useState({});
	const [securityKeyModal, setSecurityKeyModal] = React.useState(false);
	const [securityKeyCreate, setSecurityKeyCreate] = React.useState(false);
	const [showSecurityKey, setShowSecurityKey] = React.useState(false);
	const topMessageRef = React.useRef(null);

	const [videoNotesModal, setVideoNotesModal] = React.useState(false);
	const [group, setGroup] = React.useState(false);
	const [messageCount, setMessageCount] = React.useState(0);
	const [cardListData, setCardListData] = React.useState([]);
	const [latestUnecryptedMessage, setLatestUnecryptedMessage] =
		React.useState(false);
	const [latestUnecryptedMessageObj, setLatestUnecryptedMessageObj] =
		React.useState({});
	const [videoNotesData, setVideoNotesData] = React.useState({
		loading: false,
		data: [],
	});
	const [videoNoteSelected, setVideoNoteSelected] = React.useState({});
	const [newVideoNoteText, setNewVideoNoteText] = React.useState({
		value: "",
		error: "",
	});
	const [newVideoNoteType, setNewVideoNoteType] = React.useState({
		value: "",
		error: "",
	});
	const [videoNotesDeleteModal, setVideoNotesDeleteModal] = React.useState({
		loading: false,
		status: false,
		data: {},
	});
	const [addNewVideoNoteModal, setAddNewVideoNoteModal] = React.useState({
		loading: false,
		status: false,
	});
	const [newVideoNote, setNewVideoNote] = React.useState({
		value: "",
		error: "",
	});
	const [noteType, setNoteType] = React.useState({
		value: "internal",
		error: "",
	});

	const sendCallEndedMessage = React.useCallback((callData) => {
		if (socket && selectedUser && selectedUser.channelID && encryptedSecurityKey?.data?.symmetricKey) {
			const now = new Date();
			const hours = now.getHours();
			const minutes = now.getMinutes().toString().padStart(2, '0');
			const ampm = hours >= 12 ? 'PM' : 'AM';
			const formattedHours = hours % 12 || 12;
			const formattedTime = `${formattedHours}:${minutes} ${ampm}`;

			const splitKey = encryptedSecurityKey.data.symmetricKey.split("|");
			const messageText = `Video call ended at ${formattedTime}`;
			const encryptedMessage = encryptMessage(
				selectedUser.channelID,
				messageText,
				splitKey[1]
			);

			const emitMessage = {
				chatroomId: selectedUser.channelID,
				message: encryptedMessage,
				timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
				keyID: splitKey[1],
				groupId: selectedUser.groupId,
				messageType: selectedUser.type,
				user: selectedUser._id,
				isVideoCallEndMessage: true
			};

			socket.emit("chatroomMessage", emitMessage);
		}
	}, [socket, selectedUser, encryptedSecurityKey, encryptMessage]);

	const {
		groupDetails,
		videoCallInfo,
		setGroupDetails,
		refetch: fetchGroupDetails,
		isLoading: videoCallLoading
	} = useGroupDetails({ groupId: selectedUser.groupId, socket, sendCallEndedMessage })

	const userLogin = useSelector((state) => state.userLogin);
	const clinicDetails = useSelector((state) => state.clinicDetails);
	const onlineUsers = useSelector((state) => state.onlineUsers);

	React.useEffect(() => {
		if (
			userLogin &&
			userLogin.userInfo &&
			userLogin.userInfo.data &&
			userLogin.userInfo.data.user &&
			userLogin.userInfo.data.user.modules &&
			!userLogin.userInfo.data.user.modules.chat
		) {
			history.push("/");
		}
	}, [history, userLogin]);

	const scrollToBottom = () => {
		messagesStartRef &&
			messagesStartRef.current &&
			messagesStartRef.current.scrollIntoView({ behavior: "smooth" });
		messagesEndRef &&
			messagesEndRef.current &&
			messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
	};

	const scrollToMessage = () => {
		topMessageRef &&
			topMessageRef.current &&
			topMessageRef.current.scrollIntoView();
	};
	const [loadMoreMsg, setLoadMoreMsg] = React.useState(false);
	React.useEffect(() => {
		if (loadMoreMsg) {
			scrollToMessage();
		}
	}, [loadMoreMsg, lastTopMessage]);

	React.useEffect(() => {
		if (!loadMoreMsg) {
			scrollToBottom();
		}
	}, [loadMoreMsg, chatUserLoading, showSecurityKey, chatSendLoading]);

	// 	SCROLL TO BOTTOM END
	// ======================================================================================

	const getAcceptedUsers = React.useCallback(async () => {
		setAcceptedUserLoading(true);
		const data = await dispatch(getAllChatUserAction("accepted"));
		setAcceptedUsers(data);
		setAcceptedUserLoading(false);
	}, [dispatch]);

	const getAcceptedUsersWithoutLoading = React.useCallback(async () => {
		const data = await dispatch(getAllChatUserAction("accepted"));
		setAcceptedUsers(data);
	}, [dispatch]);

	React.useEffect(() => {
		if (socket) {
			socket.on("UpdateGroupResponse", (response) => {
				if (response) {
					const updatedData = acceptedUsers?.data?.map((group) => {
						if (response.groupId === group.groupId) {
							return { ...group, name: response.name };
						}
						return group;
					});
					setAcceptedUsers({ ...acceptedUsers, data: updatedData });
					if (response.groupId === selectedUser.groupId) {
						setGroupName(response.name);
						setGroupId(response.groupId);
					}
				}

				console.log(response);
			});
		}
	}, [acceptedUsers, selectedUser, setGroupName, socket]);

	useEffect(() => {
		if (socket) {
			socket.on("sendRequest", () => {
				getAcceptedUsersWithoutLoading();
			});
			socket.on("GroupRequestSendResponse", () => {
				getAcceptedUsersWithoutLoading();
			});
		}
	}, [socket]);

	const getAllUsers = React.useCallback(async () => {
		const data = await dispatch(getAllChatUserActionByNumber("fresh", 1));
		const newData = await data.data;
		setAllUsersLoading(true);
		setAllUsers(newData);
	}, [dispatch]);

	// const getGroupUsers = React.useCallback(async () => {
	//   setgroupUsersLoading(true);
	//   const data = await dispatch(getAllChatUserAction('not_available'));
	//   console.log(data, 'getGroupUsers')
	//   setAcceptedUsers(data);
	//   setgroupUsersLoading(false);
	// }, [dispatch]);

	useEffect(() => {
		if (socket) {
			socket.on("createGroupRequestSuccess", (response) => {
				if (response.data.status) {
					setToastMessage("Sending Request");
					setShowSuccessToast(true);
					getAcceptedUsers();
				} else {
					setToastMessage("Request Not Send");
					setShowErrorToast(true);
				}
			});


		}

		return () => {
			if (socket) {
				socket.off('createGroupRequestSuccess');
			}
		}
	}, [getAcceptedUsers, socket]);

	const getAllVideoNotes = React.useCallback(async () => {
		if (selectedUser && selectedUser._id && videoNotesModal) {
			setVideoNotesData({ loading: true, data: [] });
			const data = await dispatch(
				getAllVideoNotesAction(selectedUser._id)
			);

			if (data && data.data && data.data.data) {
				setVideoNotesData({ loading: false, data: data.data.data });
			} else {
				setVideoNotesData({ loading: false, data: [] });
			}
		}
	}, [dispatch, selectedUser, videoNotesModal]);

	const handleSubmitVideoNote = async () => {
		if (
			userLogin &&
			userLogin.userInfo &&
			userLogin.userInfo.data &&
			userLogin.userInfo.data.user &&
			selectedUser &&
			selectedUser._id
		) {
			if (newVideoNote.value === "" || newVideoNote.value.trim === "") {
				setNewVideoNote({
					...newVideoNote,
					error: "Please enter note ",
				});
			} else {
				setAddNewVideoNoteModal((oldData) => ({
					...oldData,
					loading: true,
				}));
				const formData = {
					noteBy: userLogin.userInfo.data.user._id,
					noteText: newVideoNote.value,
					noteType: noteType.value,
					noteOn: selectedUser._id,
				};

				const responseData = await dispatch(
					createVideoNotesAction(formData)
				);
				setAddNewVideoNoteModal((oldData) => ({
					...oldData,
					loading: false,
				}));

				if (responseData && responseData.status === "success") {
					toast.success("Note saved successfully!");
					setAddNewVideoNoteModal({ loading: false, status: false });
					getAllVideoNotes();
					setNewVideoNote({
						value: "",
						error: "",
					});
					setNoteType({
						value: "internal",
						error: "",
					});
				} else {
					setNewVideoNote({
						...newVideoNote,
						error: "Something went wrong ",
					});
				}
			}
		}
	};

	const handleEditVideoNote = async () => {
		if (videoNoteSelected && videoNoteSelected._id) {
			if (
				newVideoNoteText.value === "" ||
				newVideoNoteText.value.trim() === ""
			) {
				setNewVideoNoteText({
					...newVideoNoteText,
					error: "Please enter video note",
				});
			} else if (
				newVideoNoteType.value === "" ||
				newVideoNoteType.value.trim() === ""
			) {
				setNewVideoNoteType({
					...newVideoNoteType,
					error: "Please select video note type",
				});
			} else {
				setVideoNotesData({ ...videoNotesData, loading: true });
				const formData = {
					noteText: newVideoNoteText.value,
					noteType: newVideoNoteType.value,
				};

				const data = await dispatch(
					updateVideoNotesAction(videoNoteSelected._id, formData)
				);

				if (data && data.status === "success") {
					getAllVideoNotes();
					setNewVideoNoteText({ value: "", error: "" });
					setNewVideoNoteType({ value: "", error: "" });
					setVideoNoteSelected({});
					toast.success("Video Note Updated Successfully!");
				} else {
					setVideoNotesData({ ...videoNotesData, loading: false });
					setNewVideoNoteType({
						...newVideoNoteType,
						error: "Something went wrong.",
					});
				}
			}
		} else {
			alert("Please select video note");
		}
	};

	const handleDeleteNote = async () => {
		if (videoNotesDeleteModal.data && videoNotesDeleteModal.data._id) {
			setVideoNotesDeleteModal((oldData) => ({
				...oldData,
				loading: true,
			}));
			const data = await dispatch(
				deleteVideoNotesAction(videoNotesDeleteModal.data._id)
			);

			setVideoNotesDeleteModal((oldData) => ({
				...oldData,
				loading: false,
			}));

			if (data && data.status === "success") {
				getAllVideoNotes();
				setVideoNotesDeleteModal({
					loading: false,
					status: false,
					data: {},
				});
				toast.error("Video Note Deleted Successfully!");
			} else {
				setVideoNotesData({ ...videoNotesData, loading: false });
				setNewVideoNoteType({
					...newVideoNoteType,
					error: "Something went wrong.",
				});
			}
		} else {
			alert("Please select video note");
		}
	};

	function decryptSymmetricKey(relationID, encryptedSymmetricKey, randomKey) {
		try {
			const splitKey = encryptedSymmetricKey.split("|");
			const randomModifiedKey = randomKey.split("-").reverse().join("");
			const key = relationID
				.replace("$", "")
				.split("@")
				.reverse()
				.join(randomModifiedKey);

			const decryptedKey = CryptoJS.AES.decrypt(splitKey[0], key);
			return decryptedKey.toString(CryptoJS.enc.Utf8);
		} catch (error) {
			return "";
		}
	}

	const decryptMessageByKeyId = React.useCallback(
		async (encryptedMessage, decryptKey, channelID, symmetricKey, key) => {
			try {
				// const encKey = await dispatch(getSecurityKeyByIdAction(decryptKey));

				if (symmetricKey && key) {
					const decryptKey = decryptSymmetricKey(
						channelID,
						symmetricKey,
						key
					);
					const decryptedKey = CryptoJS.AES.decrypt(
						encryptedMessage,
						decryptKey
					);
					return decryptedKey.toString(CryptoJS.enc.Utf8);
				}
			} catch (error) {
				return "";
			}
		},
		[dispatch]
	);

	function encryptMessage(relationID, message, randomKey) {
		try {
			// const randomModifiedKey = randomKey.split('-').reverse().join('');
			// const key = relationID.replace('$', '').split('@').reverse().join(randomModifiedKey);

			if (decryptedSecurityKey !== "") {
				const encryptedMessage = CryptoJS.AES.encrypt(
					message,
					decryptedSecurityKey
				).toString();
				return encryptedMessage;
			} else return "";
		} catch (error) {
			return "";
		}
	}

	const decryptMessage = React.useCallback(
		(encryptedMessage, decryptedSecurityKey) => {
			try {
				const decryptedKey = CryptoJS.AES.decrypt(
					encryptedMessage,
					decryptedSecurityKey
				);
				return decryptedKey.toString(CryptoJS.enc.Utf8);
			} catch (error) {
				return "";
			}
		},
		[]
	);

	const checkOnline = (currentUser) => {
		return (
			onlineUsers &&
			onlineUsers.onlineUsers &&
			onlineUsers.onlineUsers.length > 0 &&
			onlineUsers.onlineUsers.some(
				(onlineUser) => onlineUser["userId"] === currentUser
			)
		);
	};

	const checkOnlineAndGetData = (currentUser) => {
		return (
			onlineUsers &&
			onlineUsers.onlineUsers &&
			onlineUsers.onlineUsers.length > 0 &&
			onlineUsers.onlineUsers.find(
				(onlineUser) => onlineUser["userId"] === currentUser
			)
		);
	};

	React.useEffect(() => {
		if (socket) {
			socket.on("went-offline", (user) => {
				// console.log(selectedUser, user.userId);
				if ((selectedUser && selectedUser._id) === user.userId) {
					const newSelectedUser = {
						...selectedUser,
						lastSeenTime: Date.now(),
					};
					setSelectedNewUser(newSelectedUser);
				}
				// if (acceptedUsers && acceptedUsers.data) {
				// 	const newAcceptedUsers = acceptedUsers.data.map((acceptedUser) => {
				// 		if (acceptedUser._id === user.userId) {
				// 			const newUser = { ...acceptedUser, lastSeenTime: Date.now() };
				// 			return newUser;
				// 		} else {
				// 			return acceptedUser;
				// 		}
				// 	});
				// 	const acceptedUserData = { ...acceptedUsers, data: newAcceptedUsers };
				// 	setAcceptedUsers(acceptedUserData);
				// 	// console.log(newAcceptedUsers, acceptedUsers);
				// }
				setNewSocketUserMsg(true);
			});
		}
	}, [socket, selectedUser]);

	const [newSocketMsg, setNewSocketMsg] = React.useState({});
	const [newSocketUserMsg, setNewSocketUserMsg] = React.useState(false);
	// const [ newSocketMsgReact, setNewSocketMsgReact ] = React.useState({});

	React.useEffect(() => {
		if (socket) {
			socket.on("newMessage", (message) => {
				if (message.message) {
					setNewSocketMsg(message.message);
				}
			});
		}
	}, [socket]);

	React.useEffect(() => {
		if (socket) {
			socket.on("RemovedFromGroupResponse", (message) => {
				setRemovedGroupId(message.groupId);
				if (selectedUser._id === removedGroupId) {
					setRemovedGroup(true);
				}
			});
		}
	}, [socket]);

	React.useEffect(() => {
		if (selectedUser._id === removedGroupId) {
			setRemovedGroup(true);
		} else setRemovedGroup(false);
	}, [removedGroupId, selectedUser, socket]);

	React.useEffect(() => {
		if (
			newSocketMsg &&
			Object.keys(newSocketMsg).length > 0 &&
			encryptedChatData &&
			encryptedChatData.data
		) {
			const combinedChat = [...encryptedChatData.data, newSocketMsg];
			const newEncChatData = { ...encryptedChatData, data: combinedChat };
			setEncryptedChatData(newEncChatData);
			setNewSocketMsg({});
		}
	}, [newSocketMsg, encryptedChatData]);

	React.useEffect(() => {
		if (socket && encryptedChatData && encryptedChatData.data) {
			socket.on('reactMessage', (message) => {

			const messageId = message.message.messageId
			encryptedChatData?.data?.forEach((chat) => {
			  if (chat.messageId === messageId)
				chat.reaction = message.message.reaction;
			});
	
			setEncryptedChatData(encryptedChatData);
			setShowReactBox({status: false, chat: {}});
		  });
		}
	  }, [socket, encryptedChatData]);

	

	// React.useEffect(
	// 	() => {
	// 		if (socket && decryptedChatData && decryptedSecurityKey !== '') {
	// 			socket.on('newMessage', (message) => {
	// 				setChatSendLoading(true);
	// 				let newMessage = {};
	// 				if (message.message.ToReplayMessage && message.message.ToReplayMessage.messageText) {
	// 					newMessage = {
	// 						...message.message,
	// 						messageText: decryptMessage(message.message.messageText, decryptedSecurityKey),
	// 						ToReplayMessage: {
	// 							...message.message.ToReplayMessage,
	// 							messageText: decryptMessage(message.message.ToReplayMessage.messageText, decryptedSecurityKey)
	// 						}
	// 					};
	// 				} else {
	// 					newMessage = {
	// 						...message.message,
	// 						messageText: decryptMessage(message.message.messageText, decryptedSecurityKey)
	// 					};
	// 				}

	// 				const deccChatData = decryptedChatData;
	// 				deccChatData.push(newMessage);
	// 				// console.log(deccChatData);
	// 				setDecryptedChatData(deccChatData);
	// 				setChatSendLoading(false);
	// 			});
	// 		}
	// 	},
	// 	[ socket, decryptedChatData, decryptMessage, decryptedSecurityKey ]
	// );

	React.useEffect(() => {
		if (socket && acceptedUsers) {
			// Ensure both socket and acceptedUsers are available
			const handleNewMessage = (message) => {
				setNewSocketUserMsg(true);
				setAcceptedUsers((prevAcceptedUsers) => {
					// Update acceptedUsers only if necessary
					const newAcceptedUsersData = prevAcceptedUsers.data.map(
						(user) => {
							if (user.channelID === message.channelID) {
								return {
									...user,
									lastMessage: message.message,
									unReadMessageCount: 0,
								};
							}
							return user;
						}
					);
					return { ...prevAcceptedUsers, data: newAcceptedUsersData };
				});
			};

			// Attach the event listener
			socket.on("newMessage", handleNewMessage);

			// Cleanup function
			return () => {
				// Remove the event listener when component unmounts or dependencies change
				socket.off("newMessage", handleNewMessage);
			};
		}
	}, [socket, acceptedUsers]);

	React.useEffect(() => {
		let isMounted = true;

		const getEncSecKey = async (channelID, lastMessageId) => {
			if (!isMounted) return;
			setChatUserLoading(true);
			try {
				const key = await dispatch(getSecurityKeyAction(channelID));
				setEncryptedSecurityKey(key);

				const chatData = await dispatch(
					getChatsAction(channelID, lastMessageId)
				);
				if (chatData && chatData.status === "success") {
					setEncryptedChatData(chatData);
				} else {
					setEncryptedChatData({
						status: "success",
						data: [],
						has_more: false,
					});
				}

				const secKeys = await dispatch(
					getAllSecurityKeyAction(channelID)
				);
				setSecurityKeys(secKeys);
			} catch (error) {
				console.error(
					"Error fetching encrypted security key or chat data:",
					error
				);
			} finally {
				if (isMounted) {
					setChatUserLoading(false);
				}
			}
		};

		if (selectedUser && selectedUser.channelID) {
			const lastMessageId = selectedUser?.lastMessage?.messageId || 0;
			getEncSecKey(selectedUser.channelID, lastMessageId);
		}

		return () => {
			isMounted = false;
		};
	}, [dispatch, selectedUser]);

	React.useEffect(() => {
		if (socket && acceptedUsers) {
			// Ensure both socket and acceptedUsers are available
			const handleNewMessage = (message) => {
				setNewSocketUserMsg(true);
				setAcceptedUsers((prevAcceptedUsers) => {
					const newAcceptedUsersData = prevAcceptedUsers.data.map(
						(user) => {
							if (user.channelID === message.channelID) {
								if (message.ToReplayMessage) {
									// console.log(selectedUser.channelID, 'selected user channelID id');
									// console.log(message.message.channelID, 'channelID id');
									// console.log(message, 'message');

									const unReadMessageCount =
										selectedUser.channelID ===
											message.message.channelID
											? 0
											: (user.unReadMessageCount || 0) +
											1;
									return {
										...user,
										lastMessage: message.ToReplayMessage,
										unReadMessageCount,
									};
								} else {
									const unReadMessageCount =
										selectedUser.channelID ===
											message.message.channelID
											? 0
											: (user.unReadMessageCount || 0) +
											1;
									return {
										...user,
										lastMessage: message.message,
										unReadMessageCount,
									};
								}
							}
							return user;
						}
					);
					return { ...prevAcceptedUsers, data: newAcceptedUsersData };
				});
			};

			// Attach the event listener
			socket.on("newIncMessage", handleNewMessage);

			// Cleanup function
			return () => {
				// Remove the event listener when component unmounts or dependencies change
				socket.off("newIncMessage", handleNewMessage);
			};
		}
	}, [socket, acceptedUsers, selectedUser]);
	

	React.useEffect(() => {
		// if (socket && selectedUser && selectedUser.channelID) {
		// 	socket.emit("joinRoom", {
		// 		chatroomId: selectedUser.channelID,
		// 		dest_user: selectedUser._id,
		// 		user: userLogin.userInfo.data.user._id,
		// 	});
		// }

		return () => {
			//Component Unmount
			if (socket && selectedUser && selectedUser.channelID) {
				socket.emit("leaveRoom", {
					chatroomId: selectedUser.channelID,
					dest_user: selectedUser._id,
					user: userLogin.userInfo.data.user._id,
				});
			}
		};
	}, [selectedUser, socket]);

	React.useEffect(() => {
		getAllUsers();
	}, [getAcceptedUsers, getAllUsers]);

	React.useEffect(() => {
		getAcceptedUsers();
	}, []);

	React.useEffect(() => {
		getAllVideoNotes();
	}, [getAllVideoNotes]);

	React.useEffect(() => {
		if (videoNoteSelected && Object.keys(videoNoteSelected).length > 0) {
			setNewVideoNoteText({
				value: videoNoteSelected.noteText,
				error: "",
			});
			setNewVideoNoteType({
				value: videoNoteSelected.noteType,
				error: "",
			});
		}
	}, [videoNoteSelected]);

	React.useEffect(
		() => {
			const acceptedUsersData = acceptedUsers && acceptedUsers.data;

			if (acceptedUsersData && selectedUser && selectedUser.channelID) {
				if (socket) {
					socket.emit("read-message", {
						chatroomId: selectedUser.channelID,
						user: userLogin?.userInfo?.data?.user?._id,
						messageId: selectedUser?.lastMessage?.messageId,
					});
				}

				const newAcceptedUsersData = acceptedUsersData.map((user) => {
					if (user.channelID === selectedUser.channelID) {
						return { ...user, unReadMessageCount: 0 };
					} else {
						return user;
					}
				});
				const newAcceptedUsers = {
					...acceptedUsers,
					data: newAcceptedUsersData,
				};
				// console.log(newAcceptedUsers);
				setAcceptedUsers(newAcceptedUsers);
			}
		},
		// eslint-disable-next-line
		[selectedUser]
	);

	React.useEffect(() => {
		const getEncSecKey = async (channelID, lastMessageId) => {
			setChatUserLoading(true);
			const key = await dispatch(getSecurityKeyAction(channelID));
			setEncryptedSecurityKey(key);

			const chatData = await dispatch(
				getChatsAction(channelID, lastMessageId)
			);
			if (chatData && chatData.status === "success") {
				setEncryptedChatData(chatData);
			} else if (chatData && chatData.status === "fail") {
				setEncryptedChatData({
					status: "success",
					data: [],
					has_more: false,
				});
			}

			const secKeys = await dispatch(getAllSecurityKeyAction(channelID));
			setSecurityKeys(secKeys);
			setChatUserLoading(false);
		};

		if (selectedUser && selectedUser.channelID) {
			const lastMessageId =
				selectedUser &&
					selectedUser.lastMessage &&
					selectedUser.lastMessage.messageId
					? selectedUser.lastMessage.messageId
					: 0;
			getEncSecKey(selectedUser.channelID, lastMessageId);
			// getAllSecurityKeyAction(selectedUser.channelID);
		}
	}, [dispatch, selectedUser]);

	React.useEffect(() => {
		const decryptKey = async () => {
			if (
				selectedUser &&
				selectedUser.channelID &&
				encryptedSecurityKey &&
				encryptedSecurityKey.status === "success" &&
				encryptedSecurityKey.data &&
				encryptedSecurityKey.data.symmetricKey
			) {
				const decryptKey = decryptSymmetricKey(
					selectedUser.channelID,
					encryptedSecurityKey.data.symmetricKey,
					encryptedSecurityKey.data.key
				);

				setDecryptedSecurityKey(decryptKey);
			}
		};
		decryptKey();
	}, [encryptedSecurityKey, selectedUser]);

	React.useEffect(() => {
		if (
			encryptedChatData &&
			encryptedChatData.status === "success" &&
			encryptedChatData.data &&
			encryptedChatData.data.length > 0 &&
			selectedUser &&
			selectedUser.channelID
		) {
			const decryptedMessages = [];
			encryptedChatData.data.forEach((message) => {
				const decSymKey = decryptSymmetricKey(
					selectedUser.channelID,
					message.symmetricKey,
					message.key
				);
				let newMsg;
				if (message.isEncryptedMessage) {
					const messageText = decryptMessage(
						message.messageText,
						decSymKey
					);
					newMsg = { ...message, messageText };

					if (
						message.ToReplayMessage &&
						message.ToReplayMessage.messageText
					) {
						const replyDecSymKey = decryptSymmetricKey(
							selectedUser.channelID,
							message.ToReplayMessage.symmetricKey,
							message.ToReplayMessage.key
						);

						const messageText = decryptMessage(
							message.messageText,
							decSymKey
						);
						const replyMessageText = decryptMessage(
							message.ToReplayMessage.messageText,
							replyDecSymKey
						);
						newMsg = {
							...message,
							messageText,
							ToReplayMessage: {
								...message.ToReplayMessage,
								messageText: replyMessageText,
							},
						};
					}
				} else {
					newMsg = { ...message };
				}

				decryptedMessages.push(newMsg);
			});
			setDecryptedChatData(decryptedMessages);
			setChatSendLoading(true);
			setTimeout(() => {
				setChatSendLoading(false);
			}, 100);
		}
	}, [encryptedChatData, decryptMessage, selectedUser]);

	React.useEffect(() => {
		const result = decryptedChatData.reduce(function (r, a) {
			const date = new Date(a.messageTime).toDateString();
			r[date] = r[date] || [];
			r[date].push(a);
			return r;
		}, Object.create(null));

		setGroupedData(result);
	}, [decryptedChatData, encryptedChatData]);

	// ======================================================================================
	// 	GET MORE CHAT ON SCROLL

	const getMoreChats = async () => {
		if (
			encryptedChatData &&
			encryptedChatData.has_more &&
			selectedUser &&
			selectedUser.channelID &&
			selectedUser.lastMessage
		) {
			// console.log(encryptedChatData.has_more);
			setLoadMoreMsg(true);
			setLastTopMessage(encryptedChatData.data[0]);
			const lastMessageId = encryptedChatData.data[0].messageId;
			const chatData = await dispatch(
				getChatsAction(selectedUser.channelID, lastMessageId - 1)
			);

			if (chatData && chatData.data) {
				const data = encryptedChatData && encryptedChatData.data;

				const combinedChat = [...chatData.data, ...data];
				setEncryptedChatData({ ...chatData, data: combinedChat });
			}
		}
	};

	// React.useEffect(() => {
	// 	const topMessage = document.querySelector('.topMessage');
	// 	const topMessageBox = document.querySelector(`.${ChatStyles.chatBox}`);
	// 	// console.log(topMessageBox);
	// 	let called = false;

	// 	topMessageBox &&
	// 		topMessage &&
	// 		topMessageBox.addEventListener('scroll', async function() {
	// 			if (!called && topMessage.offsetTop - topMessageBox.scrollTop >= 0) {
	// 				called = true;
	// 				const chatData = await getMoreChats();
	// 				called = false;
	// 				console.log(chatData);
	// 			}
	// 		});
	// });

	// 	GET MORE CHAT ON SCROLL END
	// ======================================================================================
	const sendChat = (chatText, keyID) => {
		if (socket && selectedUser && selectedUser.channelID) {
			const socketId =
				onlineUsers &&
				onlineUsers.onlineUsers &&
				onlineUsers.onlineUsers.find(
					(user) => user.userId === selectedUser._id
				);
			setLoadMoreMsg(false);
			if (selectedMessage && selectedMessage.senderId) {
				setChatSendLoading(true);
				const emitMessage = {
					chatroomId: selectedUser.channelID,
					message: chatText,
					messageId: selectedMessage.messageId,
					timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
					groupId: selectedUser.groupId,
					messageType: selectedUser.type,
					keyID,
					user: selectedUser._id,
					socketId,
				};
				socket.emit("chatroomReplyMessage", emitMessage);
				setSelectedMessage({});
			} else {
				setChatSendLoading(true);
				const emitMessage = {
					chatroomId: selectedUser.channelID,
					message: chatText,
					timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
					keyID,
					groupId: selectedUser.groupId,
					messageType: selectedUser.type,
					user: selectedUser._id,
					socketId,
				};
				socket.emit("chatroomMessage", emitMessage);
			}
			setChatText({ value: "", error: "" });
		}
	};

	const chatUser = async () => {
		setShowEmojiBox(false);
		if (chatText.value === "" || chatText.value.trim() === "") {
			setChatText({ ...chatText, error: true });
		} else {
			if (
				selectedUser &&
				selectedUser.channelID &&
				encryptedSecurityKey &&
				encryptedSecurityKey.status === "success" &&
				encryptedSecurityKey.data &&
				encryptedSecurityKey.data.symmetricKey
			) {
				const splitKey =
					encryptedSecurityKey.data.symmetricKey.split("|");
				const encryptedMessage = encryptMessage(
					selectedUser.channelID,
					chatText.value,
					splitKey[1]
				);
				sendChat(encryptedMessage, splitKey[1]);
			}
		}
	};

	const onEmojiClick = (event, emojiObject) => {
		// setChosenEmoji(emojiObject);
		// console.log(emojiObject.emoji);
		const newChatText = chatText.value + emojiObject.emoji;
		setChatText({ ...chatText, value: newChatText });
	};

	const reactToChat = async (reaction, messageId) => {
		if (socket && selectedUser && selectedUser.channelID) {
			const emitMessage = {
				chatroomId: selectedUser.channelID,
				messageId: messageId,
				user: selectedUser._id,
				reaction: reaction,
			};
			socket.emit("chatroomReactOnMessage", emitMessage);
		}
	};

	const handleCreateKey = async () => {
		if (selectedUser && selectedUser.channelID) {
			setSecurityKeyCreate(true);
			const formData = {
				relationId: selectedUser.channelID,
				keyType: "chat",
			};
			const key = await dispatch(createSecurityKeyAction(formData));

			if (key && key.status === "success") {
				toast.success("Generated and using new security key", {
					style: {
						borderRadius: "10px",
						background: "#3C0016",
						color: "#fff",
					},
				});
				setSecurityKeyModal(false);
				setChatUserLoading(true);
				const key = await dispatch(
					getSecurityKeyAction(selectedUser.channelID)
				);
				setEncryptedSecurityKey(key);

				const lastMessageId =
					selectedUser &&
						selectedUser.lastMessage &&
						selectedUser.lastMessage.messageId
						? selectedUser.lastMessage.messageId
						: 0;
				const chatData = await dispatch(
					getChatsAction(selectedUser.channelID, lastMessageId)
				);
				setEncryptedChatData(chatData);

				const secKeys = await dispatch(
					getAllSecurityKeyAction(selectedUser.channelID)
				);
				setSecurityKeys(secKeys);
				setChatUserLoading(false);
				setShowSecurityKey(false);
			}
			setSecurityKeyCreate(false);
		}
	};

	const handleMakeDefaultKey = async (keyID) => {
		if (selectedUser && selectedUser.channelID) {
			const newKey = await dispatch(makeDefaultSecurityKeyAction(keyID));

			if (newKey && newKey.status === "success") {
				toast.success(newKey.message, {
					style: {
						borderRadius: "10px",
						background: "#3C0016",
						color: "#fff",
					},
				});
				setChatUserLoading(true);
				const key = await dispatch(
					getSecurityKeyAction(selectedUser.channelID)
				);
				setEncryptedSecurityKey(key);

				const lastMessageId =
					selectedUser &&
						selectedUser.lastMessage &&
						selectedUser.lastMessage.messageId
						? selectedUser.lastMessage.messageId
						: 0;
				const chatData = await dispatch(
					getChatsAction(selectedUser.channelID, lastMessageId)
				);
				setEncryptedChatData(chatData);

				const secKeys = await dispatch(
					getAllSecurityKeyAction(selectedUser.channelID)
				);
				setSecurityKeys(secKeys);
				setChatUserLoading(false);
				setShowSecurityKey(false);
			} else if (newKey && newKey.status === "fail") {
				toast.success("Something went wrong", {
					style: {
						borderRadius: "10px",
						background: "#3C0016",
						color: "#fff",
					},
				});
			}
		}
	};
	const requestVideoCallToSelectedUser = async (user) => {
		if (socket && selectedUser) {
			const id = checkOnlineAndGetData(selectedUser._id);
			if (id) {
				// const emitMessage = {
				// 	chatroomId: id.socketId,
				// 	user: selectedUser._id,
				// 	me: socket.id,
				// 	from: userLogin.userInfo.data.user._id,
				// 	name: userLogin.userInfo.data.user.name
				// };
				// socket.emit('share-video-socket-id', emitMessage);

				history.push(
					`/make/call/video/${selectedUser._id}/${selectedUser.name}/${id.socketId}`
				);

				// window.open(
				//   `/#/make/call/video/${selectedUser._id}/${selectedUser.name}/${id.socketId}`,
				//   '',
				//   'width=1200, height=850, top=100, left=100'
				// );
			}
			// socket.on('requested-video-socket-id', (data) => {
			// 	console.log(data);
			// 	history.push(`/make/call/video/${selectedUser._id}/${selectedUser.name}/${data.requestedId}`)
			// })
			// socket.on('share-my-video-socket-id', (data) => {
			// 	console.log('selected', data);
			// 	socket.emit('requested-video-socket-id', { requestedId: socket.id, to: data.me })
			// })
			// socket.on("callUser", ({ from, name: callerName, signal }) => {
			// 	console.log('Got a incoming call');
			// 	// setCall({ isReceivingCall: true, from, name: callerName, signal });
			// });
		}
	};

	React.useEffect(() => {
		if (socket) {
			socket.on("unencryptedMessageSocket", (message) => {
				if (message && message?.messageObject) {
					const newDecrypt = [...decryptedChatData];
					newDecrypt.push(message?.messageObject);
					setDecryptedChatData(newDecrypt);
				}
				setLatestUnecryptedMessage(true);
				// setLatestUnecryptedMessageObj(message?.messageObj)
			});
		}
	}, [socket, groupedData, decryptedChatData]);

	React.useEffect(() => {
		setChatText({ ...chatText, value: "" });
	}, [selectedUser]);

	return (
		<Content
			// style={{ paddingLeft: "2rem", paddingRight: "2rem" }}
			headerTitle="Chat"
			currentMenu="chat"
			addBtn={false}
			socket={socket}
			searchBox={false}
		>
			<div className={ChatStyles.content}>
				<div className={ChatStyles.chatLists}>
					<TabLinks
						link1Label="All Chats"
						link1To="/chat"
						setShowAddGroupMoal={setShowAddGroupMoal}
						link1Active={true}
						link1Notification=""
						link2Label="Chat Requests"
						link2To="/chat-requests"
						link2Active={false}
						link2Notification={
							acceptedUsers &&
							acceptedUsers.pendingRequestCount > 0 &&
							acceptedUsers.pendingRequestCount
						}
					/>
					<SearchBox
						value={acceptedUserSearch}
						onChange={(e) => setAcceptedUserSearch(e.target.value)}
					/>
					<div className={ChatStyles.chatLists_users1}>
						{acceptedUserLoading ? (
							<ChatUserSkeleton />
						) : acceptedUsers &&
							acceptedUsers.data &&
							acceptedUsers.data.length === 0 ? (
							<NoData title="No users to chat with" />
						) : acceptedUsers &&
							acceptedUsers.data &&
							acceptedUsers.data.filter((user) =>
								user.name
									.toLowerCase()
									.includes(acceptedUserSearch.toLowerCase())
							).length === 0 ? (
							<NoData title="No results found" />
						) : (
							acceptedUsers &&
							acceptedUsers.data &&
							acceptedUsers.data
								.filter((user) =>
									user.name
										.toLowerCase()
										.includes(
											acceptedUserSearch.toLowerCase()
										)
								)
								.sort(
									(a, b) =>
										new Date(b?.lastMessage?.messageTime) -
										new Date(a?.lastMessage?.messageTime)
								)
								.map((user) =>
									user.type === "group" ? (
										<GroupCard
											key={user._id}
											setGroupName={setGroupName}
											data={{
												user,
												checkOnline,
												setLoadMoreMsg,
												newSocketUserMsg,
												selectedUser,
												setShowSelectedUser,
												setSelectedUser,
												setSelectedNewUser,
												setDecryptedChatData,
												setEncryptedChatData,
												setShowSecurityKey,
												decryptMessageByKeyId,
												socket
											}}

										/>
									) : (
										<UserComp
											key={user._id}
											data={{
												user,
												checkOnline,
												setLoadMoreMsg,
												newSocketUserMsg,
												selectedUser,
												setShowSelectedUser,
												setSelectedUser,
												setSelectedNewUser,
												setDecryptedChatData,
												setEncryptedChatData,
												setShowSecurityKey,
												decryptMessageByKeyId,
											}}
										/>
									)
								)
						)}
					</div>
				</div>

				{selectedUser && showSelectedUser ? (
					<div className={ChatStyles.chat}>
						{selectedUser.channelID &&
							selectedUser.type !== "group" ? (
							<SelectedUser
								data={{
									chatUserLoading,
									checkOnline,
									checkOnlineAndGetData,
									selectedUser,
									selectedNewUser,
									setShowSecurityKey,
									showSecurityKey,
									setVideoNotesModal,
									group,
									setGroup,
								}}
								requestVideoCallToSelectedUser={
									requestVideoCallToSelectedUser
								}
							/>
						) : showSelectedUser &&
							selectedUser.type === "group" ? (
							<SelectedGroup
								data={{
									chatUserLoading,
									setGroupName,
									groupName,
									checkOnline,
									checkOnlineAndGetData,
									selectedUser,
									selectedNewUser,
									setShowSecurityKey,
									showSecurityKey,
									setVideoNotesModal,
									group,
									socket,
									setGroup,
									groupId,
									setGroupId,
									videoCallLoading,
									videoCallInfo,
									encryptedSecurityKey,
									encryptMessage
								}}
								setShowGroupInfoMoal={setShowGroupInfoMoal}
								requestVideoCallToSelectedUser={
									requestVideoCallToSelectedUser
								}
							/>
						) : null}
						{!chatUserLoading && showSecurityKey ? (
							<div className={ChatStyles.chatBox}>
								<div ref={messagesStartRef} />
								<div style={{ minHeight: "calc(60vh - 8rem)" }}>
									{securityKeys &&
										securityKeys.data &&
										securityKeys.data.length > 0 &&
										securityKeys.data.map((key) => (
											<div
												className={
													ChatStyles.chatBox_key
												}
												key={key._id}
											>
												<h5>{key.key}</h5>
												{key && key.default ? (
													<p>Using this as default</p>
												) : (
													<button
														onClick={() =>
															handleMakeDefaultKey(
																key._id
															)
														}
													>
														Make this default
													</button>
												)}
											</div>
										))}
								</div>
								<div className={ChatStyles.chatBox_key_add}>
									<button
										onClick={() =>
											setShowSecurityKey(!showSecurityKey)
										}
									>
										Go Back
									</button>
									<button
										onClick={() =>
											setSecurityKeyModal(true)
										}
									>
										New Key
									</button>
								</div>
							</div>
						) : (
							<div className={ChatStyles.chatBox}>
								<p className={ChatStyles.chatBox_msg}>
									Messages are end to end encrypted.
								</p>
								{encryptedChatData &&
									encryptedChatData.has_more && (
										<div
											className={
												ChatStyles.chatBox_loadMore
											}
										>
											<button
												className={
													ChatStyles.chatBox_loadMoreBtn
												}
												onClick={getMoreChats}
											>
												Load More Chats
											</button>
										</div>
									)}

								<div className={ChatStyles.chatBox_chats}>
									{chatUserLoading ? (
										<ChatsSkeleton />
									) : decryptedChatData &&
										decryptedChatData.length > 0 ? (
										groupedData &&
										Object.keys(groupedData).length > 0 &&
										Object.keys(groupedData).map(
											(key) =>
												groupedData[key].length > 0 &&
												(groupedData[key].push(),
													(
														<Fragment key={key}>
															<p
																className={
																	ChatStyles.chatBox_date
																}
															>
																{key ===
																	new Date(
																		Date.now()
																	).toDateString()
																	? "Today"
																	: key ===
																		new Date(
																			Date.now() -
																			24 *
																			60 *
																			60 *
																			1000
																		).toDateString()
																		? "Yesterday"
																		: key}
															</p>
															{groupedData[key].map(
																(
																	chat,
																	chatIndex
																) => {
																	const myId =
																		userLogin &&
																		userLogin.userInfo &&
																		userLogin
																			.userInfo
																			.data &&
																		userLogin
																			.userInfo
																			.data
																			.user &&
																		userLogin
																			.userInfo
																			.data
																			.user
																			._id;
																	const myName =
																		userLogin &&
																		userLogin.userInfo &&
																		userLogin
																			.userInfo
																			.data &&
																		userLogin
																			.userInfo
																			.data
																			.user &&
																		userLogin
																			.userInfo
																			.data
																			.user
																			?.name;
																	return chat.isEncryptedMessage ? (
																		<div
																			key={
																				chat._id
																			}
																			ref={
																				lastTopMessage &&
																					Object.keys(
																						lastTopMessage
																					)
																						.length >
																					0 &&
																					lastTopMessage._id ===
																					chat._id
																					? topMessageRef
																					: null
																			}
																			className={[
																				myId ===
																					chat.senderId
																					? ChatStyles.chatBox_out_chat
																					: ChatStyles.chatBox_inc_chat,
																			].join(
																				" "
																			)}
																		>
																			<div
																				className={
																					ChatStyles.chatBox_chat_msgs
																				}
																			>
																				{myId ===
																					chat.senderId && (
																						<button
																							className={
																								ChatStyles.chatBox_chat_inc_msg_reply
																							}
																							onClick={() => {
																								setSelectedMessage(
																									chat
																								);
																							}}
																						>
																							<svg>
																								<use
																									xlinkHref={`/assets/sprite.svg#icon-reply`}
																								/>
																							</svg>
																						</button>
																					)}

																				<div
																					className={
																						myId ===
																							chat.senderId
																							? ChatStyles.chatBox_chat_out_msg
																							: ChatStyles.chatBox_chat_inc_msg
																					}
																				>
																					{chat &&
																						chat.ToReplayMessage && (
																							<div
																								className={
																									ChatStyles.chat_reply
																								}
																							>
																								<div>
																									<p
																										className={
																											ChatStyles.chat_reply_msg
																										}
																									>
																										{/* {
																										chat
																											.ToReplayMessage
																											.messageText
																									} */}
																										{chat.ToReplayMessage.messageText
																											.split(
																												"\n"
																											)
																											.map(
																												(
																													message,
																													index
																												) => (
																													<span
																														key={
																															index
																														}
																														style={{
																															display:
																																"block",
																														}}
																													>
																														{
																															message
																														}
																													</span>
																												)
																											)}
																									</p>
																									<p
																										className={
																											ChatStyles.chat_reply_msg_name
																										}
																									>
																										{chat
																											.ToReplayMessage
																											.senderName +
																											" at " +
																											new Date(
																												chat.ToReplayMessage.messageTime
																											).toLocaleString()}
																									</p>
																								</div>
																							</div>
																						)}
																					{chat.senderName !==
																						myName ? (
																						<span
																							className={
																								ChatStyles.senderName
																							}
																						>
																							{
																								chat.senderName
																							}
																						</span>
																					) : null}
																					{/* <p> */}
																					{chat.messageText
																						.split(
																							"\n"
																						)
																						.map(
																							(
																								message,
																								index
																							) => (
																								<span
																									key={
																										index
																									}
																									style={{
																										display:
																											"block",
																									}}
																								>
																									{
																										message
																									}
																								</span>
																							)
																						)}
																					{/* </p> */}
																					{chat &&
																						chat.reaction &&
																						chat
																							.reaction
																							.length >
																						0 && (
																							<div
																								className={
																									ChatStyles.chat_reply_reaction
																								}
																							>
																								{chat.reaction.map(
																									(
																										react,
																										index
																									) => (
																										<p
																											key={
																												index
																											}
																										>
																											{react.react
																												? react.react
																												: ""}
																										</p>
																									)
																								)}
																							</div>
																						)}
																				</div>

																				{myId !==
																					chat.senderId && (
																						<button
																							className={
																								ChatStyles.chatBox_chat_inc_msg_smiley
																							}
																							onClick={() =>
																								setShowReactBox(
																									{
																										status: !showReactBox.status,
																										chat,
																									}
																								)
																							}
																						>
																							<svg>
																								<use
																									xlinkHref={`/assets/sprite.svg#icon-emoji-happy`}
																								/>
																							</svg>
																						</button>
																					)}

																				{myId !==
																					chat.senderId && (
																						<button
																							className={
																								ChatStyles.chatBox_chat_inc_msg_reply
																							}
																							onClick={() =>
																								setSelectedMessage(
																									chat
																								)
																							}
																						>
																							<svg>
																								<use
																									xlinkHref={`/assets/sprite.svg#icon-reply`}
																								/>
																							</svg>
																						</button>
																					)}
																			</div>
																			<p
																				className={
																					ChatStyles.chatBox_chat_time
																				}
																			>
																				{new Date(
																					chat.messageTime
																				)
																					.toLocaleTimeString()
																					.replace(
																						/(.*)\D\d+/,
																						"$1"
																					)}
																			</p>

																			{myId !==
																				chat.senderId &&
																				showReactBox.status &&
																				showReactBox.chat &&
																				showReactBox
																					.chat
																					._id ===
																				chat._id && (
																					<div
																						className={
																							ChatStyles.chatBox_chat_react
																						}
																					>
																						<button
																							onClick={() =>
																								reactToChat(
																									"👍",
																									chat.messageId
																								)
																							}
																						>
																							👍
																						</button>
																						<button
																							onClick={() =>
																								reactToChat(
																									"❤️",
																									chat.messageId
																								)
																							}
																						>
																							❤️
																						</button>
																						<button
																							onClick={() =>
																								reactToChat(
																									"😊",
																									chat.messageId
																								)
																							}
																						>
																							😊
																						</button>
																						<button
																							onClick={() =>
																								reactToChat(
																									"🙏",
																									chat.messageId
																								)
																							}
																						>
																							🙏
																						</button>
																					</div>
																				)}
																		</div>
																	) : (
																		<p
																			className={
																				ChatStyles.chatBox_msg
																			}
																			key={
																				chat._id
																			}
																		>
																			{chat &&
																				chat.messageText +
																				" at " +
																				new Date(
																					chat.messageTime
																				)
																					.toLocaleTimeString()
																					.replace(
																						/(.*)\D\d+/,
																						"$1"
																					)}
																			{/* {dummyMessages.map((item) => item.text)} */}
																		</p>
																	);
																}
															)}
														</Fragment>
													))
										)
									) : (
										<p className={ChatStyles.noChats}>
											Looks like you have not chatted with
											this user previously.
										</p>
									)}
								</div>
								{removedGroup ? (
									<div className={ChatStyles.removedText}>
										You can't send messages to this group
										because you're no longer a participant.
									</div>
								) : (
									<div
										className={
											chatText.error
												? ChatStyles.chat_send_errr
												: ChatStyles.chat_send
										}
									>
										{selectedMessage &&
											selectedMessage.senderId && (
												<div
													className={
														ChatStyles.chat_reply
													}
												>
													<div>
														<p
															className={
																ChatStyles.chat_reply_msg
															}
														>
															{
																selectedMessage.messageText
															}
														</p>
														<p
															className={
																ChatStyles.chat_reply_msg_name
															}
														>
															{userLogin &&
																userLogin.userInfo &&
																userLogin.userInfo
																	.data &&
																userLogin.userInfo
																	.data.user &&
																userLogin.userInfo
																	.data.user
																	._id ===
																selectedMessage.senderId
																? "You"
																: selectedMessage.senderName}{" "}
															at{" "}
															{new Date(
																selectedMessage.messageTime
															).toLocaleString()}
														</p>
													</div>
													<button
														className={
															ChatStyles.chat_reply_close
														}
														onClick={() =>
															setSelectedMessage(
																{}
															)
														}
													>
														<svg>
															<use
																xlinkHref={`/assets/sprite.svg#icon-close`}
															/>
														</svg>
													</button>
												</div>
											)}
										<div
											className={
												ChatStyles.chat_send_textBox
											}
										>
											<textarea
												type="text"
												placeholder="Type your message here"
												className={
													ChatStyles.chat_send_text
												}
												value={chatText.value}
												onChange={(e) =>
													setChatText({
														value: e.target.value,
													})
												}
												onKeyPress={(e) => {
													if (
														e.key === "Enter" &&
														!e.shiftKey
													) {
														e.preventDefault();
														chatUser();
													}
												}}
											/>

											<div
												className={
													ChatStyles.chat_send_smiley_container
												}
											>
												<button
													className={
														ChatStyles.chat_send_smiley
													}
													onClick={() =>
														setShowEmojiBox(
															!showEmojiBox
														)
													}
												>
													<svg>
														<use
															xlinkHref={`/assets/sprite.svg#icon-emoji-happy`}
														/>
													</svg>
												</button>
												{showEmojiBox && (
													<div
														className={
															ChatStyles.chatBox_chat_emoji
														}
													>
														<button
															className={
																ChatStyles.chat_reply_close
															}
															onClick={() =>
																setShowEmojiBox(
																	!showEmojiBox
																)
															}
														>
															<svg>
																<use
																	xlinkHref={`/assets/sprite.svg#icon-close`}
																/>
															</svg>
														</button>
														<Picker
															onEmojiClick={
																onEmojiClick
															}
															pickerStyle={{
																boxShadow:
																	"none",
																border: "none",
															}}
														/>
													</div>
												)}
											</div>
											{chatSendLoading ? (
												<button
													className={
														ChatStyles.chat_send_message_loading
													}
												>
													<div className="dot-stretching" />
												</button>
											) : (
												<button
													className={
														ChatStyles.chat_send_message
													}
													onClick={chatUser}
												>
													<svg>
														<use
															xlinkHref={`/assets/sprite.svg#icon-send`}
														/>
													</svg>
												</button>
											)}
										</div>
									</div>
								)}
								<div ref={messagesEndRef} />
							</div>
						)}
					</div>
				) : (
					<ChatboxPlaceholder />
				)}
			</div>

			<NewSecurityKeyModal
				securityKeyModal={securityKeyModal}
				loading={securityKeyCreate}
				handleCreateKey={handleCreateKey}
				handleCreateKeyModalClose={() => setSecurityKeyModal(false)}
			/>

			{videoNotesModal && (
				<VideoNotesModal
					addNew={true}
					onAddNewClick={() =>
						setAddNewVideoNoteModal({
							loading: false,
							status: true,
						})
					}
					videoNotesModal={videoNotesModal}
					loading={videoNotesData.loading}
					videoNotes={videoNotesData.data}
					selectedUser={selectedUser}
					handleModalClose={() => setVideoNotesModal(false)}
					data={{
						videoNoteSelected,
						setVideoNoteSelected,
						newVideoNoteText,
						setNewVideoNoteText,
						newVideoNoteType,
						setNewVideoNoteType,
						handleEditVideoNote,
						setVideoNotesDeleteModal,
					}}
				/>
			)}

			{addNewVideoNoteModal.status && (
				<AddVideoNoteModal
					loading={addNewVideoNoteModal.loading}
					show={addNewVideoNoteModal.status}
					handleSubmit={handleSubmitVideoNote}
					handleModalClose={() =>
						setAddNewVideoNoteModal({
							loading: false,
							status: false,
						})
					}
					data={{
						newVideoNote,
						setNewVideoNote,
						noteType,
						setNoteType,
					}}
				/>
			)}

			{videoNotesDeleteModal.status && (
				<DeleteVideoNoteModal
					loading={videoNotesDeleteModal.loading}
					handleSubmit={handleDeleteNote}
					videoNotesDeleteModal={videoNotesDeleteModal}
					handleModalClose={() =>
						setVideoNotesDeleteModal({
							loading: false,
							status: false,
							data: {},
						})
					}
				/>
			)}
			{showAddGroupMoal && (
				<AddGroupUsersMoal
					socket={socket}
					allUsersLoading={acceptedUserLoading}
					acceptedUsers={allUsers}
					getAcceptedUsers={getAcceptedUsers}
					showModal={showAddGroupMoal}
					setShowModal={setShowAddGroupMoal}
				/>
			)}
			{showGroupInfoMoal && (
				<GroupInfoModal
					socket={socket}
					groupName={groupName}
					setGroupName={setGroupName}
					setShowSelectedUser={setShowSelectedUser}
					groupId={selectedUser.groupId}
					getAcceptedUsers={getAcceptedUsers}
					acceptedUsers={acceptedUsers}
					showModal={showGroupInfoMoal}
					setShowModal={setShowGroupInfoMoal}
				/>
			)}
			<ErrorToast
				message={toastMessage}
				showToast={showErrorToast}
				onClose={() => setShowErrorToast(false)}
				duration={3500}
			/>
			<SuccessToast
				message={toastMessage}
				showToast={showSuccessToast}
				onClose={() => setShowSuccessToast(false)}
				duration={3500}
			/>
		</Content>
	);
};

export default ChatScreen;
