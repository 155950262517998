import React from 'react';
import { Avatar } from 'antd';
import styles from './CustomAvatar.module.css';

const getInitials = (name) => {
    if (!name) return 'P';

    const nameParts = name.split(' ').filter(part => part.length > 0);

    if (nameParts.length === 1) {
        return nameParts[0].charAt(0);
    }

    return `${nameParts[0].charAt(0)}${nameParts[nameParts.length - 1].charAt(0)}`;
};

const CustomAvatar = ({ name, size = 48 }) => {
    const initials = getInitials(name);

    return (
        <div className={styles.avatarContainer}>
            <Avatar
                size={size}
                className={styles.avatar}
            >
                {initials}
            </Avatar>
        </div>
    );
};

export default CustomAvatar;